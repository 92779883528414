<template>
    <div>
        <nav
            ref="navbarRef"
            id="navbar"
            :class="['navbar navbar-expand-lg bg-light', { shadow: shadow }]"
        >
            <a class="navbar-brand d-flex align-items-center" href="/">
                <img width="156" height="22" :src="logoSrc" alt="Prizific" />
            </a>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 me-0 me-lg-2">
                    <li class="nav-item">
                        <a class="nav-link" href="/ugynoksegek">
                            Ügynökségek
                        </a>
                    </li>
                </ul>
            </div>
            <a
                class="btn btn-primary d-none d-lg-block text-light"
                href="/#contact"
            >
                Beszélgessünk
            </a>
            <MobileNavItem
                className="d-lg-none"
                @click="showMenu"
                @click.native="setActiveMenu('menu')"
                icon="menu"
                name=""
            />
        </nav>
        <transition name="menu">
            <div
                v-if="menuState"
                class="mobile-navbar-menu d-flex flex-column d-lg-none align-items-center py-4"
            >
                <div class="mobile-navbar-menu-close">
                    <span @click="hideMenu" class="material-icons-outlined">
                        highlight_off
                    </span>
                </div>
                <h3>Menü</h3>
                <div class="mt-auto">
                    <NavbarSecondaryMobile
                        :menuItems="
                            typeof menuItems == 'string'
                                ? JSON.parse(menuItems)
                                : menuItems
                        "
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import NavbarSecondaryMobile from "./NavbarSecondaryMobile.vue";
import MobileNavItem from "./MobileNavItem.vue";

export default {
    name: "navbar-inner-component",
    components: {
        NavbarSecondaryMobile,
        MobileNavItem,
    },
    props: {
        shadow: {
            type: Boolean,
            required: false,
            default: false,
        },
        logoSrc: {
            type: String,
            required: false,
        },
        menuItems: {
            required: false,
            default: [
                {
                    name: "LAKOSSÁGI",
                    link: "https://prizific.com",
                    active: false,
                },
                {
                    name: "FMCG",
                    link: "https://b2b.prizific.com",
                    active: true,
                },
                {
                    name: "ÜGYNÖKSÉGI",
                    link: "https://b2b.prizific.com/ugynoksegek",
                    active: false,
                },
            ],
        },
    },
    data() {
        return {
            activeMenu: "",
            menuState: false,
        };
    },
    mounted() {
        window.addEventListener("resize", this.isMobileSize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.isMobileSize);
    },
    methods: {
        showMenu() {
            this.disableScroll();

            this.menuState = true;
        },
        hideMenu() {
            this.enableScroll();

            this.menuState = false;
        },
        /**
         * Set active menu
         */
        setActiveMenu(name) {
            this.activeMenu = name;
        },
        whichMenuIsActive(menu) {
            if (this.activeMenu !== menu) {
                return "";
            } else {
                return "active";
            }
        },
        disableScroll() {
            document.body.style.overflow = "hidden";
        },
        enableScroll() {
            document.body.style.overflow = "";
        },
        isMobileSize() {
            if (window.innerWidth > 991) {
                if (this.menuState) {
                    this.hideMenu;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-primary {
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.navbar {
    border-radius: 0 !important;
    padding: 8px 10px;

    .navbar-brand {
        padding: 0;
        margin-left: 8px;
    }

    .nav-item {
        font-weight: bold;

        .nav-link {
            padding: 8px 24px;
            color: black;

            &:hover {
                color: #e44f60;
            }
        }
    }

    @media (min-width: 992px) {
        border-radius: 13px !important;
    }
}

.mobile-navbar {
    z-index: 9999;
    border-top: 1px solid #cecece;
    background-color: white;
}
.mobile-navbar-menu {
    position: fixed;
    z-index: 10000;
    top: 0;
    // top: 47px;
    left: 0;
    bottom: 0;
    // bottom: 64px;
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    background-color: white;

    a {
        text-decoration: none;
        font-weight: bold;
        color: #e44f60;
        font-size: 16px;
        padding: 0.5rem 0.25rem;
    }

    a.nuxt-link-exact-active {
        color: #e44f60;
    }

    h3 {
        color: #e44f60;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 1.5rem;
    }

    .mobile-navbar-menu-item {
        text-decoration: none;
        font-weight: 500 !important;
        color: #e44f60;
        font-size: 16px;
        padding: 0.5rem 0.25rem;
    }

    .mobile-navbar-menu-close {
        width: 100%;
        margin-right: 2rem;
        text-align: right;
        cursor: pointer;

        .material-icons-outlined {
            font-size: 32px;
            color: #e44f60;
        }
    }
}

.menu-enter-active,
.menu-leave-active {
    transition: transform 0.45s;
    z-index: 9998;
    transform: translateY(0);
}
.menu-enter,
.menu-leave-to {
    z-index: 9998;
    transform: translateY(-200%);
}
</style>
