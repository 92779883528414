<template>
    <div
        :class="['mobile-nav-item d-flex flex-column text-center', className]"
        @click.native="handleClickNav(navUrlId, navUrl)"
    >
        <template v-if="icon != 'menu'">
            <div class="mobile-nav-item__icon">
                <span class="material-icons-outlined"> {{ icon }} </span>
            </div>
            <div class="mobile-nav-item__name">
                {{ name }}
            </div>
        </template>
        <template v-else>
            <div class="mobile-nav-item__icon">
                <span class="material-icons-outlined"> {{ icon }} </span>
            </div>
            <div class="mobile-nav-item__name">
                {{ name }}
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "mobile-nav-item-component",
    components: {},
    props: {
        icon: {
            type: String,
            require: false,
        },
        name: {
            type: String,
            require: false,
        },
        className: {
            type: String,
            require: false,
        },
        navUrl: {
            type: String,
            require: false,
        },
        navUrlId: {
            type: String,
            require: false,
        },
    },
    data() {
        return {
            activeMenu: "",
            menuState: false,
        };
    },
    methods: {
        handleClickNav(navUrlId, navUrl) {
            this.$emit("click", { id: navUrlId, url: navUrl });
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-nav-item {
    position: relative;
    cursor: pointer;

    .el-avatar {
        --el-avatar-size: 30px;
        margin-bottom: 4px;
        margin-top: 1px;
    }

    &__icon {
        font-size: 0;
        margin: 8px 0;
        // margin-bottom: 2px;

        .material-icons-outlined {
            font-size: 32px;
        }
    }

    &__name {
        font-size: 11px;
    }
}
</style>
