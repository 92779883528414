<template>
    <div class="slot-machine">
        <div
            v-for="(slot, index) in slots"
            :ref="
                (el) => {
                    if (el) slotsRef[index] = el;
                }
            "
            :key="index"
            class="slot"
        >
            <div class="slot__window">
                <div class="slot__wrap">
                    <div
                        v-for="(item, index) in slot.items"
                        :key="index"
                        class="slot__item slot__item--copy"
                    >
                        {{ item }}
                    </div>
                    <div
                        v-for="(item, index) in slot.items"
                        :key="index"
                        class="slot__item"
                    >
                        {{ item }}
                    </div>
                    <div
                        v-for="(item, index) in slot.items"
                        :key="index"
                        class="slot__item slot__item--copy"
                    >
                        {{ item }}
                    </div>
                </div>
            </div>
            <div ref="winnerInfo" class="winner-info h-100">
                <div class="container h-100">
                    <div class="row h-100">
                        <div
                            class="col-12 col-lg-6 offset-lg-3 position-relative h-100"
                        >
                            <div class="the-winner-is">A nyertes</div>
                            <transition name="fadeDown">
                                <div
                                    v-show="showReplacementWinnersWrapper"
                                    class="others-wrapper text-center"
                                >
                                    <div
                                        v-if="numberOfReWinners"
                                        :class="[
                                            'fs-4 fw-bold mb-4',
                                            {
                                                'text-secondary':
                                                    changeReplacementWinnersTitleColor,
                                            },
                                        ]"
                                    >
                                        Pótnyertesek
                                    </div>
                                    <div v-if="numberOfReWinners">
                                        <button
                                            v-if="showReplacementWinnersButton"
                                            @click="
                                                handleCollapseReplacementWinners
                                            "
                                            class="btn btn-outline-secondary fw-bold"
                                        >
                                            Pótnyertesek felfedése
                                        </button>
                                    </div>
                                    <div
                                        ref="collapseReplacementWinners"
                                        class="collapse replacement-winners"
                                    >
                                        <ol class="d-inline-block">
                                            <li
                                                v-for="(
                                                    reWinner, index
                                                ) in reWinners"
                                                :key="index"
                                            >
                                                {{ reWinner }}
                                            </li>
                                        </ol>
                                        {{ calcPlayersForGame }}
                                    </div>
                                    <button
                                        class="btn btn-secondary fw-bold mt-4"
                                        @click="handleReDraw"
                                    >
                                        Újraindítás
                                    </button>
                                    <br />
                                    <button
                                        class="btn btn-link text-secondary fw-bold mt-2 text-decoration-none"
                                        @click="newGameWithNewPlayers"
                                    >
                                        Másik sorsolás indítása új játékosokkal
                                    </button>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref="slotMachineTriangle" class="slot-machine-triangle"></div>
        <div ref="slotMachineHelper" class="slot-machine-helper"></div>
        <DrawFireworks
            :startAnimation="startFireworksAnimation"
            @animationDone="handleFireworksAnimationDone"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref, onBeforeUpdate } from "vue";
import { Collapse } from "bootstrap";
import DrawFireworks from "./DrawFireworks.vue";

/**
 * The window.requestAnimationFrame() method tells the browser that you wish to perform
 * an animation and requests that the browser calls a specified function to update an animation
 * before the next repaint.
 * The method takes a callback as an argument to be invoked before the repaint.
 */
const nextRePaint =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    function (cb) {
        window.setTimeout(cb, 1000 / 60);
    };

export default {
    name: "draw-slot-machine",
    components: {
        DrawFireworks,
    },
    setup() {
        const slotsRef = ref([]);
        const slotMachineTriangle = ref(null);
        const slotMachineHelper = ref(null);
        const collapseReplacementWinners = ref(null);

        // onBeforeUpdate(() => {
        //     slotsRef.value = [];
        //     slotMachineTriangle.value = null;
        //     slotMachineHelper.value = null;
        //     collapseReplacementWinners.value = null;
        // });

        return {
            slotsRef,
            slotMachineTriangle,
            slotMachineHelper,
            collapseReplacementWinners,
        };
    },
    data() {
        return {
            document: null,
            window: null,
            slots: [
                {
                    items: [],
                },
            ],
            options: null,
            startedAt: null,
            next: null,
            collapseReplacementWinnersElement: null,
            changeReplacementWinnersTitleColor: false,
            startFireworksAnimation: false,
            showReplacementWinnersButton: true,
            showReplacementWinnersWrapper: false,
        };
    },
    mounted() {
        this.window = window;
        this.document = document;
        this.slots[0].items = this.calcPlayersForGame;
        this.collapseReplacementWinnersElement = new Collapse(
            this.collapseReplacementWinners[0],
            {
                toggle: false,
            }
        );

        setTimeout(() => {
            this.start();
        }, 1500);
    },
    computed: {
        ...mapGetters({
            numberOfReWinners: "drawStore/getNumberOfReWinners",
            players: "drawStore/playersAfterProcess",
        }),

        /**
         * Players state/mutation
         */
        formPlayers: {
            get() {
                return this.$store.state.drawStore.drawForm.players;
            },
            set(value) {
                this.$store.commit("drawStore/setPlayers", value);
            },
        },

        /**
         * Show playground state/mutation
         */
        showOverlay: {
            get() {
                return this.$store.state.drawStore.drawPlayground.showOverlay;
            },
            set(value) {
                this.$store.commit("drawStore/setShowOverlay", value);
            },
        },

        /**
         * Winner state/mutation
         */
        winner: {
            get() {
                return this.$store.state.drawStore.drawPlayground.winner;
            },
            set(value) {
                this.$store.commit("drawStore/setWinner", value);
            },
        },

        /**
         * Replacement Winners state/mutation
         */
        reWinners: {
            get() {
                return this.$store.state.drawStore.drawPlayground
                    .replacementWinners;
            },
            set(value) {
                this.$store.commit("drawStore/setReWinners", value);
            },
        },

        /**
         * Reset form state/mutation
         */
        resetForm: {
            get() {
                return this.$store.state.drawStore.drawForm.reset;
            },
            set(value) {
                this.$store.commit("drawStore/setResetForm", value);
            },
        },

        calcPlayersForGame() {
            let playersArray = [];
            let randomNumbers = [];
            let number;

            if (this.players.length < 10) {
                do {
                    this.players.forEach((player) => playersArray.push(player));
                } while (playersArray.length <= 10);

                return playersArray;
            } else {
                do {
                    number = Math.floor(Math.random() * this.players.length);

                    if (!randomNumbers.includes(number)) {
                        randomNumbers.push(number);
                    }
                } while (randomNumbers.length !== 10);

                randomNumbers.forEach((number) =>
                    playersArray.push(this.players[number])
                );

                return playersArray;
            }
        },
    },
    methods: {
        /**
         * Init slot machine, draw winner
         */
        start() {
            if (this.options) {
                return;
            }

            this.winner = null;

            this.options = this.slots.map((data, i) => {
                const slot = this.slotsRef[i];
                const winner = Math.floor(Math.random() * data.items.length);
                const reWinners = [];
                const slotItemMarginTop = parseInt(
                    this.window.getComputedStyle(
                        slot.querySelector(".slot__item")
                    ).marginTop
                );
                const slotHeight =
                    slot.querySelector(".slot__item").offsetHeight +
                    slotItemMarginTop;

                this.winner = data.items[winner];
                this.reWinners = this.randomReWinners(winner);

                // console.log("Kivalasztott nyertes: ", i, data.items[winner]);

                const options = {
                    el: slot.querySelector(".slot__wrap"),
                    height: data.items.length * slotHeight,
                    finalPos:
                        (data.items.length + winner) * slotHeight -
                        (this.window.innerHeight -
                            slotHeight / (Math.floor(Math.random() * 4) + 1)) /
                            2 +
                        slotHeight / (Math.floor(Math.random() * 4) + 2) -
                        slotItemMarginTop,
                    startOffset: 12000 + Math.random() * 500,
                    duration: 10000,
                    isFinished: false,
                };

                return options;
            });

            // nextRePaint(this.forceGatheringAnimate);

            nextRePaint(this.animate);
        },

        // forceGatheringAnimate(timestamp) {
        //     if (this.startedAt == null) {
        //         this.startedAt = timestamp;
        //     }

        //     const timeDiff = timestamp - this.startedAt;

        //     this.options.forEach((option) => {
        //         if (option.isFinished) {
        //             return;
        //         }

        //         const timeRemaining = Math.max(2000 - timeDiff, 0);
        //         const power = 2;
        //         const offset =
        //             (Math.pow(timeRemaining, power) / Math.pow(2000, power)) *
        //             2000;
        //         const pos = 1 * Math.floor(offset % option.height);

        //         option.el.style.transform = "translateY(" + pos + "px)";
        //     });

        //     if (this.options.every((o) => o.isFinished)) {
        //         this.options = null;
        //         this.startedAt = null;
        //         console.log("Force gathering vege");
        //         nextRePaint(this.animate);
        //     } else {
        //         nextRePaint(this.forceGatheringAnimate);
        //     }
        // },

        /**
         * Slot machine animation
         * @params {}
         */
        animate(timestamp) {
            if (this.startedAt == null) {
                this.startedAt = timestamp;
            }

            const timeDiff = timestamp - this.startedAt;

            this.options.forEach((option, index) => {
                if (option.isFinished) {
                    return;
                }

                const timeRemaining = Math.max(option.duration - timeDiff, 0);
                const power = 4;
                const offset =
                    (Math.pow(timeRemaining, power) /
                        Math.pow(option.duration, power)) *
                    option.startOffset;

                // If negative, slots move from top to bottom
                const pos =
                    -1 * Math.floor((offset + option.finalPos) % option.height);

                option.el.style.transform = "translateY(" + pos + "px)";

                this.checkCollision(option, index);

                if (timeDiff > option.duration) {
                    // console.log("Animacio vege", option, pos, option.finalPos);
                    option.isFinished = true;
                }
            });

            if (this.options.every((o) => o.isFinished)) {
                this.highlightWinner();
                this.startFireworksAnimation = true;
                this.options = null;
                this.startedAt = null;
                // console.log("Porgetes vege");
            } else {
                nextRePaint(this.animate);
            }
        },

        /**
         * Helper function
         */
        checkCollision(option, i) {
            const slot = this.slotsRef[i];
            const slotItemMarginTop = parseInt(
                this.window.getComputedStyle(slot.querySelector(".slot__item"))
                    .marginTop
            );
            const slotHeight = slot.querySelector(".slot__item").offsetHeight;
            const slotHelperLineOffsetTop = this.slotMachineHelper.offsetTop;

            const style = this.window.getComputedStyle(option.el);
            const matrix = new WebKitCSSMatrix(style.transform);

            slot.querySelectorAll(".slot__item").forEach((slotItem, index) => {
                const slotItemStart = slotItem.offsetTop;
                const slotItemEnd = slotItem.offsetTop + slotItem.offsetHeight;

                if (
                    Math.abs(matrix.m42) +
                        slotHelperLineOffsetTop -
                        slotItemMarginTop >=
                        slotItemStart &&
                    Math.abs(matrix.m42) +
                        slotHelperLineOffsetTop -
                        slotItemMarginTop <=
                        slotItemEnd
                ) {
                    slotItem.classList.add("active");
                } else {
                    slotItem.classList.remove("active");
                }
            });
        },

        /**
         * Highlit the winner and hide other elements
         */
        highlightWinner() {
            this.options.forEach((option, index) => {
                const slot = this.slotsRef[index];

                this.slotMachineTriangle.style.opacity = 0;

                slot.querySelectorAll(".slot__item").forEach((slotItem) => {
                    if (slotItem.classList.contains("active")) {
                        slotItem.classList.add("winner");
                        option.el.classList.add("end");
                    } else {
                        slotItem.classList.add("hide");
                    }
                    setTimeout(() => {
                        this.document
                            .querySelector(".the-winner-is")
                            .classList.add("end");
                    }, 350);
                });
            });
        },

        /**
         * Handle replacement winners collapse
         */
        handleCollapseReplacementWinners() {
            // event.target.remove();
            this.showReplacementWinnersButton = false;
            this.changeReplacementWinnersTitleColor = true;
            this.collapseReplacementWinnersElement.show();
        },

        /**
         * Handle fireworks animation done
         * Then show replacement winners button and others
         */
        handleFireworksAnimationDone(state) {
            this.showReplacementWinnersWrapper = state;
        },

        /**
         * Reset playground and start a new draw
         */
        handleReDraw() {
            this.showReplacementWinnersButton = true;
            this.changeReplacementWinnersTitleColor = false;
            this.collapseReplacementWinnersElement.hide();
            this.showReplacementWinnersWrapper = false;
            this.startFireworksAnimation = false;
            this.document
                .querySelector(".the-winner-is")
                .classList.remove("end");

            setTimeout(() => {
                this.document
                    .querySelectorAll(".slot__item")
                    .forEach((slotItem) => {
                        this.slotMachineTriangle.style.opacity = 1;
                        this.document
                            .querySelector(".slot__wrap")
                            .classList.remove("end");
                        slotItem.classList.remove("winner");
                        slotItem.classList.remove("end");
                        slotItem.classList.remove("active");
                        slotItem.classList.remove("hide");
                    });
            }, 500);

            setTimeout(() => {
                this.start();
            }, 500);
        },

        /**
         * Handle on click newGameWithNewPlayers
         */
        newGameWithNewPlayers() {
            this.formPlayers = [];
            this.resetForm = true;
            this.$emit("newGameWithNewPlayers", false);
        },

        /**
         * Random replacement winners
         */
        randomReWinners(winnerNumber) {
            let number;
            let randomWinners = [];
            let randomNumbers = [];

            if (this.numberOfReWinners) {
                do {
                    number = Math.floor(Math.random() * this.players.length);

                    if (
                        number !== winnerNumber &&
                        !randomNumbers.includes(number)
                    ) {
                        randomNumbers.push(number);
                    }
                } while (randomNumbers.length !== this.numberOfReWinners);

                randomNumbers.forEach((number) =>
                    randomWinners.push(this.players[number])
                );
            }

            return randomWinners;
        },
    },
};
</script>

<style lang="scss">
.fadeDown-enter-active,
.fadeDown-leave-active {
    opacity: 1;
    margin-top: 0;
    transition: opacity 0.5s ease, margin-top 0.5s ease;
}

.fadeDown-enter-from,
.fadeDown-leave-to {
    opacity: 0;
    margin-top: -8rem;
}
</style>
