<template>
    <div
        ref="tagContainer"
        class="tag-wrapper"
        :style="[{ 'overflow-x': isMobileDevice() ? 'scroll' : 'hidden' }]"
    >
        <div class="d-flex gap-3 mt-4">
            <template v-for="(tag, index) in tags" :key="index">
                <span v-if="index < midpointIndex" class="tag">
                    {{ tag }}
                </span>
            </template>
            <div class="tag-helper">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div class="d-flex gap-3 mt-3">
            <template v-for="(tag, index) in tags" :key="index">
                <span v-if="index >= midpointIndex" class="tag">
                    {{ tag }}
                </span>
            </template>
            <div class="tag-helper">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TagList",
    props: {
        tags: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isDragging: false,
            initialX: 0,
            initialScrollLeft: 0,
            velocityX: 0,
            momentumFrameId: 0,
        };
    },
    computed: {
        midpointIndex() {
            return Math.floor(this.tags.length / 2);
        },
    },
    mounted() {
        this.tagContainer = this.$refs.tagContainer;

        this.tagContainer.addEventListener("mousedown", this.onMouseDown);
        this.tagContainer.addEventListener("mouseleave", this.onMouseLeave);
        this.tagContainer.addEventListener("mouseup", this.onMouseUp);
        this.tagContainer.addEventListener("mousemove", this.onMouseMove);
    },
    beforeDestroy() {
        this.tagContainer.removeEventListener("mousedown", this.onMouseDown);
        this.tagContainer.removeEventListener("mouseleave", this.onMouseLeave);
        this.tagContainer.removeEventListener("mouseup", this.onMouseUp);
        this.tagContainer.removeEventListener("mousemove", this.onMouseMove);
    },
    methods: {
        onMouseDown(event) {
            this.isDragging = true;
            this.initialX = event.pageX - this.tagContainer.offsetLeft;
            this.initialScrollLeft = this.tagContainer.scrollLeft;
            this.cancelMomentumTracking();
        },
        onMouseLeave() {
            this.isDragging = false;
        },
        onMouseUp() {
            this.isDragging = false;
            this.startMomentumTracking();
        },
        onMouseMove(event) {
            if (!this.isDragging) return;

            event.preventDefault();
            const currentX = event.pageX - this.tagContainer.offsetLeft;
            const dragDistance = currentX - this.initialX;
            const previousScrollLeft = this.tagContainer.scrollLeft;

            this.tagContainer.scrollLeft =
                this.initialScrollLeft - dragDistance;
            this.velocityX = this.tagContainer.scrollLeft - previousScrollLeft;
        },
        startMomentumTracking() {
            this.cancelMomentumTracking();
            this.momentumFrameId = requestAnimationFrame(this.applyMomentum);
        },
        cancelMomentumTracking() {
            cancelAnimationFrame(this.momentumFrameId);
        },
        applyMomentum() {
            this.tagContainer.scrollLeft += this.velocityX;
            this.velocityX *= 0.95;

            if (Math.abs(this.velocityX) > 0.5) {
                this.momentumFrameId = requestAnimationFrame(
                    this.applyMomentum
                );
            }
        },
        isMobileDevice() {
            return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        },
    },
};
</script>

<style lang="scss">
.tag-wrapper {
    padding-left: 36px;

    .tag {
        display: flex;
        align-items: center;
        color: white;
        padding: 12px 24px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        pointer-events: none;
        user-select: none;
        text-wrap: nowrap;
        background: linear-gradient(
            90deg,
            rgba(#3a89a2, 0.9),
            rgba(#3a89a2, 0.3)
        );

        &:last-child {
            margin-right: 16px;
        }
    }

    .tag-helper {
        pointer-events: none;
        user-select: none;
        text-wrap: nowrap;
    }
}
</style>
