import "bootstrap";
import anime from "animejs/lib/anime.es.js";
import { createApp } from "vue";
import Card from "./components/Card.vue";
import Slider from "./components/Slider.vue";
import DrawForm from "./components/DrawForm.vue";
import ContactForm from "./components/ContactForm.vue";
import SectionTitle from "./components/SectionTitle.vue";
import DrawPlayground from "./components/DrawPlayground.vue";
import PromoSiteList from "./components/PromoSiteList.vue";
import HiveList from "./components/HiveList.vue";
import BenefitsListItem from "./components/BenefitsListItem.vue";
import CookieSettingsModal from "./components/CookieSettingsModal.vue";
import Navbar from "./components/Navbar.vue";
import MobileNav from "./components/MobileNav.vue";
import OpenCookieSettingsButton from "./components/OpenCookieSettingsButton.vue";
import TagList from "./components/TagList.vue";
import Tab from "./components/Tab.vue";
import store from "./store";

if (document.getElementById("main") !== null) {
    createApp({
        components: {
            "navbar-component": Navbar,
            "mobile-navbar-component": MobileNav,
            "cookie-settings-modal-component": CookieSettingsModal,
            "section-title-component": SectionTitle,
            "slider-component": Slider,
            "tag-list": TagList,
            "tab-component": Tab,
            "benefits-list-item-component": BenefitsListItem,
            "promo-site-list-component": PromoSiteList,
            "hive-list-component": HiveList,
            "card-component": Card,
            "contact-form": ContactForm,
            "open-cookie-settings-button-component": OpenCookieSettingsButton,
            "draw-form": DrawForm,
            "draw-playground": DrawPlayground,
        },
    })
        .use(store)
        .mount("#main");
}

if (document.getElementById("why") !== null) {
    const animationBoot = anime({
        targets: ".boot",
        keyframes: [{ top: -100 }, { top: -114 }],
        loop: 3,
        duration: 800,
        easing: "easeOutInBack",
        direction: "alternate",
        autoplay: false,
    });

    const animationRocks = anime({
        targets: [".rock-1", ".rock-2", ".rock-3"],
        translateY: 200,
        translateX: 40,
        opacity: 1,
        rotate: "2turn",
        duration: 500,
        autoplay: false,
        easing: "easeOutBounce",
        delay: anime.stagger(800, { start: 200 }),
    });

    let animationDone = false;

    window.onscroll = function () {
        const element = document.getElementById("why");

        if (
            !animationDone &&
            element.getBoundingClientRect().top < 400 &&
            element.getBoundingClientRect().top > 350
        ) {
            animationDone = true;
            animationBoot.play();
            animationRocks.play();
        }
    };
}

const floatingWithMouse = document.querySelectorAll(".floatingWithMouse");

let currentX = 0;
let targetX = 0;
const maxRange = 16;
const friction = 0.04;

document.addEventListener("mousemove", function (event) {
    const mouseX = event.clientX;
    const windowWidth = window.innerWidth;
    const normalizedPosition = mouseX / windowWidth - 0.5;

    targetX = -normalizedPosition * maxRange * 2;
});

function animate() {
    currentX += (targetX - currentX) * friction;

    floatingWithMouse.forEach((element) => {
        element.style.left = `${currentX}px`;
    });

    requestAnimationFrame(animate);
}

animate();

const element = document.getElementById("rotateElement");

function rotateElement() {
    let angle = 0;
    const speed = 0.1;

    function rotate() {
        angle += speed;
        if (angle >= 360) {
            angle = 0;
        }

        element.style.transform = `rotate(${angle}deg) translateY(-50%)`;
        requestAnimationFrame(rotate);
    }

    rotate();
}

rotateElement();
