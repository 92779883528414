<template>
    <div class="tabs">
        <div class="d-flex">
            <div class="tab-navigation ms-auto me-auto mb-6">
                <div
                    :class="[
                        'btn btn-secondary-outline',
                        { active: activeButton == 'promo' },
                    ]"
                    @click="setActiveTab('promo')"
                >
                    Promo sites
                </div>
                <div
                    :class="[
                        'btn btn-secondary-outline',
                        { active: activeButton == 'hive' },
                    ]"
                    @click="setActiveTab('hive')"
                >
                    Kaptár
                </div>
            </div>
        </div>

        <div v-if="activeButton == 'promo'">
            <slot name="promo"></slot>
        </div>
        <div v-if="activeButton == 'hive'">
            <slot name="hive"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "tab-component",
    props: {},
    data() {
        return {
            activeButton: "promo",
        };
    },
    mounted() {},
    methods: {
        setActiveTab(tabName) {
            this.activeButton = tabName;
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-secondary-outline {
    padding: 12px 24px;
    color: #3a89a2;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 16px;
    border: 2px solid transparent;
    background-color: rgba(#3a89a2, 0.1);

    &.active {
        border: 2px solid #3a89a2;

        &:focus {
            color: #3a89a2;
            border: 2px solid #3a89a2;
            background-color: rgba(#3a89a2, 0.1);
        }
    }

    &:focus {
        color: #3a89a2;
        border: 2px solid #3a89a2;
        background-color: rgba(#3a89a2, 0.1);
    }

    &:active {
        color: #3a89a2;
        border: 2px solid #3a89a2;
        background-color: rgba(#3a89a2, 0.1);

        &:focus {
            color: #3a89a2;
            border: 2px solid #3a89a2;
            background-color: rgba(#3a89a2, 0.1);
        }
    }
}
</style>
