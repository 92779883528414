<template>
    <div class="d-none d-md-flex row px-0">
        <template v-for="(item, index) in list" :key="index">
            <template v-if="index === 0">
                <div class="col-12 col-md-4 offset-md-2">
                    <benefits-list-item-component
                        :title="item.title"
                        :description="item.description"
                        :number="item.number"
                        :animation-class="item.animationClass"
                    >
                    </benefits-list-item-component>
                </div>
                <div class="col-12 col-md-4 d-none d-md-flex">
                    <img
                        class="img-fluid mt-auto mb-auto"
                        src="/images/v2/illustration.webp"
                    />
                </div>
            </template>
            <template v-else>
                <div
                    :class="[
                        'col-12 col-md-4',
                        {
                            'mb-12 mb-md-6 mb-md-0': index === index.length - 1,
                        },
                    ]"
                >
                    <benefits-list-item-component
                        :title="item.title"
                        :description="item.description"
                        :number="item.number"
                        :animation-class="item.animationClass"
                    >
                    </benefits-list-item-component>
                </div>
            </template>
        </template>
    </div>
    <div class="d-block d-md-none row px-0">
        <template v-for="(item, index) in list" :key="index">
            <template v-if="index < 3">
                <div class="col-12 col-md-4 offset-md-2">
                    <benefits-list-item-component
                        :title="item.title"
                        :description="item.description"
                        :number="item.number"
                        :animation-class="item.animationClass"
                    >
                    </benefits-list-item-component>
                </div>
            </template>
        </template>
        <div class="collapse" id="collapseExample">
            <template v-for="(item, index) in list" :key="index">
                <template v-if="index >= 3">
                    <div
                        :class="[
                            'col-12 col-md-4',
                            {
                                'mb-12 mb-md-6 mb-md-0':
                                    index === index.length - 1,
                            },
                        ]"
                    >
                        <benefits-list-item-component
                            :title="item.title"
                            :description="item.description"
                            :number="item.number"
                            :animation-class="item.animationClass"
                        >
                        </benefits-list-item-component>
                    </div>
                </template>
            </template>
        </div>
        <div class="col-12 mb-12 mb-md-6 mb-md-0 text-center">
            <button
                class="btn btn-more ms-auto me-auto gap-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
            >
                További előnyök
                <span class="up material-icons">keyboard_arrow_up</span>
                <span class="down material-icons">keyboard_arrow_down</span>
            </button>
        </div>
    </div>
</template>

<script>
import BenefitsListItemComponent from "./BenefitsListItem.vue";

export default {
    name: "hive-list",
    props: {},
    components: {
        BenefitsListItemComponent,
    },
    data() {
        return {
            list: [
                {
                    title: "Szisztematikus engagement növelés",
                    description:
                        "Elszigetelt promóciók helyett, customer journey - aktivitásokon keresztül. Ösztönözd a fogyasztót mindig úgy, ahogy az az aktuális engagement szintje szerint a legeredményesebb.",
                    number: "1",
                    animationClass: "hiveBenefitsFirstListItem",
                },
                {
                    title: "SSO - Egyszer regisztráció",
                    description:
                        "Egy promócióban a fogyasztók részvételi hajlandóságát jelentősen befolyásolja a feladat, amit el kell végezni egy pályázat rögzítéséhez. A Kaptárral 2 kattintásra csökkenthető.",
                    number: "2",
                    animationClass: "hiveBenefitsSecondListItem",
                },
                {
                    title: "Tartsd meg az adatokat",
                    description:
                        "Kaptár nélkül egy promóció után minden adatot törölnöd kell. De a Kaptár segítségével megtarthatod és hasznosíthatod őket.",
                    number: "3",
                    animationClass: "hiveBenefitsThirdListItem",
                },
                {
                    title: "Kommunikálj közvetlenül",
                    description:
                        "Fogyasztóid a Kaptáron keresztül képes vagy közvetlenül is kommunikálni a fogyasztóiddal. Akár a Kaptárral vagy egy általad használt CRM-en keresztül (API).",
                    number: "4",
                    animationClass: "hiveBenefitsFourthListItem",
                },
                {
                    title: "Tapasztalat",
                    description:
                        "Korábban sok iparágból szerzett digitális termékfejlesztési tapasztalatunkat építjük be a Prizificbe, ezért találkozhatsz olyan megoldásokkal, amelyekkel korábban soha.",
                    number: "5",
                    animationClass: "hiveBenefitsFifthListItem",
                },
                {
                    title: "Mental availability növelése",
                    description:
                        "TV spotok helyett/mellett érd el újra és újra ugyanazokat a fogyasztókat, hogy top of mind tudd tartani a márkád.",
                    number: "6",
                    animationClass: "hiveBenefitsSixthListItem",
                },
                {
                    title: "Tudj meg mindent",
                    description:
                        "Finomhangold a kampányaid célzását, üzenetét a kampányban gyűjtött adatok alapján! Figyeld, hogy alakulnak az eredmények kampányról kampányra!",
                    number: "7",
                    animationClass: "hiveBenefitsSeventhListItem",
                },
                {
                    title: "Integrálható marketingeszközök",
                    description:
                        "Van loyalty rendszeretek? Vagy mobilalkalmazás? Kösd össze őket a Prizifickel! A promóció mostantól a marketing-vérkeringés részévé válhat.",
                    number: "8",
                    animationClass: "hiveBenefitsEighthListItem",
                },
                {
                    title: "Költséghatékonyság",
                    description:
                        "Folyamatokat alakítunk ki, amelyek weboldalakat építenek fel. Így lehet radikálisan kevesebb az egy kampányra eső HR költségünk, és ezért adhatunk sokkal jobb árat a piaci szereplők zöménél.",
                    number: "9",
                    animationClass: "benefitsNinthListItem",
                },
                {
                    title: "GDPR",
                    description:
                        "A személyes adatokat GDPR kompatibilisen kezeljük.",
                    number: "10",
                    animationClass: "benefitsTenthListItem",
                },
            ],
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>
<style lang="scss">
.btn-more {
    background-color: transparent;
    border: 0;
    color: #f8fbf4;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    align-items: center;

    .material-icons {
        &.up {
            display: none;
        }
    }
}

.collapse {
    &.show + .text-center {
        .btn-more {
            .material-icons {
                &.down {
                    display: none;
                }
                &.up {
                    display: inline;
                }
            }
        }
    }
}
</style>
