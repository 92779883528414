<template>
    <transition name="fade">
        <div v-if="showOverlay" class="draw-playground">
            <div class="container">
                <div class="draw-playground-header">
                    <div class="position-relative px-4">
                        <img
                            :src="logo"
                            class="position-relative d-none d-md-inline-block z-index-3"
                        />
                        <button
                            @click="closePlayground"
                            class="btn btn-text draw-playground-close text-primary d-flex align-items-center z-index-3 px-0"
                        >
                            <span class="material-icons-outlined fs-1">
                                highlight_off
                            </span>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6 offset-lg-3 px-6 px-md-0">
                        <DrawSlotMachine
                            @newGameWithNewPlayers="handleClosePlayground"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import DrawSlotMachine from "./DrawSlotMachine.vue";

export default {
    name: "draw-playground",
    components: {
        DrawSlotMachine,
    },
    props: {
        logo: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            document: null,
        };
    },
    mounted() {
        this.document = document;
    },
    computed: {
        /**
         * Show playground state/mutation
         */
        showOverlay: {
            get() {
                return this.$store.state.drawStore.drawPlayground.showOverlay;
            },
            set(value) {
                this.$store.commit("drawStore/setShowOverlay", value);
            },
        },
    },
    methods: {
        closePlayground() {
            this.showOverlay = false;
        },
        handleClosePlayground() {
            this.showOverlay = false;
        },
    },
    watch: {
        showOverlay(newVal) {
            if (newVal) {
                this.document.body.style.overflow = "hidden";
            } else {
                this.document.body.style.overflow = "initial";
            }
        },
    },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.z-index-3 {
    z-index: 3;
}
</style>
