<template>
    <div ref="benefitsListItemRef" class="benefits-list-item">
        <h3 class="mb-0">{{ title }}</h3>
        <p>
            {{ description }}
        </p>
        <div :class="['benefits-list-item-number', animationClass]">
            {{ start }}
        </div>
    </div>
</template>

<script>
import ScrollMagic from "scrollmagic";
import anime from "animejs/lib/anime.es.js";

export default {
    name: "benefits-list-item",
    props: {
        title: String,
        description: String,
        number: String,
        animationClass: String,
    },
    data() {
        return {
            start: 24,
            animated: false,
        };
    },
    mounted() {
        // Elements
        const benefitsListItem = this.$refs.benefitsListItemRef;

        // Scroll magic
        const controller = new ScrollMagic.Controller();

        const scene = new ScrollMagic.Scene({
            triggerHook: 0.8,
            triggerElement: benefitsListItem,
        })
            .setClassToggle(`.${this.animationClass}`, "fade")
            .reverse(false)
            .addTo(controller);

        scene.on("update", (event) => {
            if (parseInt(event.startPos) <= event.scrollPos) {
                if (!this.animated) {
                    this.countAnimation(parseInt(this.number));
                }
            }
        });
    },
    methods: {
        countAnimation(currentVal) {
            const obj = { n: this.start };
            anime({
                targets: obj,
                n: currentVal,
                round: 1,
                duration: 1000,
                easing: "linear",
                update: () => {
                    this.start = obj.n;
                },
            });

            this.animated = true;
        },
    },
};
</script>
