<template>
    <!-- File upload button -->
    <div class="file-upload me-6">
        <div v-if="!file" class="file-upload-text">
            <div class="d-flex align-items-center gap-3">
                <span class="material-icons-outlined">file_upload</span>
                <strong> Játékosok listájának feltöltése </strong>
            </div>
        </div>
        <div class="file-upload-text" v-if="file">
            <div class="d-flex flex-column">
                <div>
                    <strong>Feltöltve</strong>
                </div>
                <div>
                    {{ file.name }}
                </div>
            </div>
            <div
                @click="removeFile()"
                class="d-flex align-items-center ms-auto text-primary"
            >
                <span class="material-icons-outlined">delete_outline</span>
            </div>
        </div>
        <input
            v-if="!file"
            type="file"
            multiple
            @change="filesChange($event)"
            accept=".xls, .xlsx"
        />
    </div>
    <!-- File upload button end -->
</template>

<script>
import readXlsxFile from "read-excel-file";

export default {
    name: "file-upload",
    data() {
        return {
            file: null,
        };
    },
    computed: {
        players: {
            get() {
                return this.$store.state.drawStore.drawForm.players;
            },
            set(value) {
                this.$store.commit("drawStore/setPlayers", value);
            },
        },
        /**
         * Replacement winners state/mutation
         */
        replacementWinners: {
            get() {
                return this.$store.state.drawStore.drawForm.replacementWinners;
            },
            set(value) {
                this.$store.commit("drawStore/setReplacementWinners", value);
            },
        },
        /**
         * Reset form state/mutation
         */
        resetForm: {
            get() {
                return this.$store.state.drawStore.drawForm.reset;
            },
            set(value) {
                this.$store.commit("drawStore/setResetForm", value);
            },
        },
    },
    methods: {
        async filesChange(event) {
            this.file = event?.target?.files ? event.target.files[0] : null;

            if (this.file === null) {
                return;
            }

            this.players = [];

            await readXlsxFile(this.file).then((rows) => {
                rows.forEach((row) => {
                    this.players.push(row[0]);
                });
            });
        },
        removeFile() {
            this.file = null;
            this.players = [];
        },
    },
    watch: {
        resetForm(newVal) {
            if (newVal) {
                this.replacementWinners = 0;
                this.removeFile();
                this.resetForm = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_variables";

.file-upload {
    position: relative;
    background: $white;
    color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: 412px;
    max-height: 72px;
    min-width: 100%;
    min-height: 72px;

    .file-upload-text {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 75%;
    }

    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
</style>
