"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const they_said_js_1 = require("../static-texts/they-said.js");
const swiper_1 = require("swiper");
const vue_1 = require("swiper/vue");
require("swiper/css");
require("swiper/css/navigation");
require("swiper/css/pagination");
exports.default = {
    name: "slider",
    components: {
        Swiper: vue_1.Swiper,
        SwiperSlide: vue_1.SwiperSlide,
    },
    setup() {
        return {
            modules: [swiper_1.Navigation, swiper_1.Pagination, swiper_1.A11y, swiper_1.Autoplay],
        };
    },
    data() {
        return {
            texts: new they_said_js_1.TheySaidStaticTexts().texts,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                dynamicBullets: false,
            },
            autoplay: {
                delay: 6000,
            },
            breakpoints: {
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    centeredSlides: false,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    centeredSlides: false,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    centeredSlides: true,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    centeredSlides: true,
                },
            },
        };
    },
};
