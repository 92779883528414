<template>
    <div class="row">
        <div class="col-md-10 offset-md-1">
            <h2 class="fw-bold">Sorsolás beállítása</h2>
            <form @submit.prevent="handleDrawFormSubmitted">
                <div class="mt-4 mb-3">
                    <label class="form-label d-block">
                        Játékosok listájának feltöltése (xls, xlsx)
                    </label>
                    <div class="row align-items-center">
                        <div class="col-12 col-md-7">
                            <!-- File upload -->
                            <FileUpload :ignoreFirstRow="ignoreFirstRow" />
                            <!-- File upload end -->
                        </div>
                        <div
                            class="col-12 col-md-5 text-start text-md-end mt-3 mt-md-0"
                        >
                            <!-- Example xls/xlsx -->
                            <a
                                class="btn btn-outline-light fw-bold"
                                :href="example"
                            >
                                Példa táblázat letöltése
                            </a>
                            <!-- Example xls/xlsx end -->
                        </div>
                    </div>
                    <div class="mt-4 mb-3">
                        <label class="form-label d-block">
                            Pótnyertesek száma
                        </label>
                        <div class="d-flex align-items-center gap-4">
                            <vue-number-input
                                v-model="replacementWinners"
                                :min="0"
                                :max="maxReplacementWinners"
                                inline
                                center
                                controls
                            ></vue-number-input>
                            <!-- Mi ez? -->
                        </div>
                    </div>
                    <div class="d-flex align-items-center flex-wrap gap-3 mt-4">
                        <!-- Ignore first row checkbox -->
                        <div class="form-check mt-2">
                            <input
                                id="ignoreFirstRow"
                                class="form-check-input"
                                type="checkbox"
                                v-model="ignoreFirstRow"
                                @change="ignoreFirstRowHandler"
                            />
                            <label
                                class="form-check-label"
                                for="ignoreFirstRow"
                            >
                                A táblázat első sorát hagyja figyelmen kívül (ha
                                az oszlop nevét tartalmazza)
                            </label>
                        </div>
                        <!--Ignore first row checkbox -->
                    </div>
                </div>
                <div class="mt-5 mb-1 text-center">
                    <button
                        class="btn btn-primary text-light"
                        :disabled="!drawButtonState"
                    >
                        Sorsolás indítása
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import FileUpload from "./FileUpload.vue";

export default {
    name: "draw-form",
    components: {
        VueNumberInput,
        FileUpload,
    },
    props: {
        example: {
            type: String,
            required: true,
        },
    },
    mounted() {
        console.log(this.example);
    },
    computed: {
        ...mapGetters({
            players: "drawStore/playersAfterProcess",
        }),

        /**
         * Replacement winners state/mutation
         */
        replacementWinners: {
            get() {
                return this.$store.state.drawStore.drawForm.replacementWinners;
            },
            set(value) {
                this.$store.commit("drawStore/setReplacementWinners", value);
            },
        },

        /**
         * Ignored rist row state/mutation
         */
        ignoreFirstRow: {
            get() {
                return this.$store.state.drawStore.drawForm.ignoreFirstRow;
            },
            set(value) {
                this.$store.commit("drawStore/setIgnoreFirstRow", value);
            },
        },

        /**
         * Show playground state/mutation
         */
        showOverlay: {
            get() {
                return this.$store.state.drawStore.drawPlayground.showOverlay;
            },
            set(value) {
                this.$store.commit("drawStore/setShowOverlay", value);
            },
        },

        /**
         * If players at least 2 names then return false
         * @return {boolean}
         */
        drawButtonState() {
            return this.players.length >= 2 ? true : false;
        },

        /**
         * Max replacement winners
         */
        maxReplacementWinners() {
            return this.players?.length ? this.players.length - 1 : 999999999;
        },
    },
    methods: {
        handleDrawFormSubmitted() {
            this.showOverlay = true;
        },
    },
    watch: {
        maxReplacementWinners(newVal) {
            if (newVal < this.replacementWinners) {
                this.replacementWinners = newVal;
            }
        },
    },
};
</script>

<style lang="scss">
.vue-number-input {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .vue-number-input__button {
        position: relative;
        width: 24px;
        height: 24px;
        color: white;
        background: transparent;
        border: 2px solid white;
        border-radius: 50% !important;

        &::before {
            height: 2px;
        }

        &::after {
            width: 2px;
        }

        &::before,
        &::after {
            background-color: white !important;
        }

        &:disabled {
            cursor: not-allowed;

            &::before,
            &::after {
                background-color: white !important;
            }
        }
    }

    &.vue-number-input--inline {
        input {
            width: 6rem;
            text-align: center;
        }
    }

    &.vue-number-input--controls {
        input {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .vue-number-input__input {
        font-weight: bold;
        color: #3a89a2 !important;
        line-height: 1.25 !important;
        min-height: 1.25rem !important;
    }
}
</style>
