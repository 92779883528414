<template>
    <form id="contact-form" v-on:submit.prevent="save">
        <transition name="fade">
            <div v-if="saveSuccessful" class="alert alert-success" role="alert">
                Sikeres jelentkezés!
            </div>
        </transition>
        <transition name="fade">
            <div v-if="saveFailed" class="alert alert-danger" role="alert">
                Sikertelen jelentkezés! Kérjük próbáld meg újra késöbb.
            </div>
        </transition>
        <!-- Name -->
        <div class="mb-3">
            <label for="contact-form-input-name" class="form-label fw-bold">
                Neved
            </label>
            <input
                type="text"
                :class="[
                    'form-control',
                    { 'is-invalid': checkValidation('name') },
                ]"
                id="contact-form-input-name"
                placeholder=""
                v-model="name"
            />
            <div class="invalid-feedback" v-if="checkValidation('name')">
                {{ getError("name") }}
            </div>
        </div>
        <!-- Name end -->

        <!-- Email -->
        <div class="mb-3">
            <label for="contact-form-input-email" class="form-label fw-bold">
                Mail címed
            </label>
            <input
                type="email"
                :class="[
                    'form-control',
                    { 'is-invalid': checkValidation('email') },
                ]"
                id="contact-form-input-email"
                placeholder=""
                v-model="email"
            />
            <div class="invalid-feedback" v-if="checkValidation('email')">
                {{ getError("email") }}
            </div>
        </div>
        <!-- Email end -->

        <!-- Message -->
        <div class="mb-3">
            <label for="contact-form-input-message" class="form-label fw-bold">
                Üzeneted
            </label>
            <textarea
                class="form-control"
                id="contact-form-input-message"
                rows="3"
                v-model="comment"
            ></textarea>
        </div>
        <!-- Message end -->

        <!-- Privacy Policy checkbox -->
        <div class="form-check">
            <input
                :class="[
                    'form-check-input',
                    { 'is-invalid': checkValidation('gdpr') },
                ]"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                v-model="gdpr"
            />
            <label class="form-check-label" for="flexCheckDefault">
                Elfogadom az
                <a href="#" class="text-white">adatvédelmi nyilatkozatot</a>, és
                engedélyezem, hogy a megadott elérhetőségeken felvegyétek velem
                a kapcsolatot..
            </label>
            <div class="invalid-feedback" v-if="checkValidation('gdpr')">
                {{ getError("gdpr") }}
            </div>
        </div>
        <!-- Privacy Policy checkbox -->

        <button
            :disabled="formSubmitted"
            class="btn btn-primary text-light fw-bold mt-4"
            type="submit"
        >
            Küldés
        </button>
    </form>
</template>

<script>
import axios from "axios";

export default {
    name: "contact-form",
    data() {
        return {
            name: "",
            email: "",
            comment: "",
            gdpr: false,
            errors: {},
            formSubmitted: false,
            saveSuccessful: false,
            saveFailed: false,
        };
    },
    methods: {
        save() {
            // console.log({
            //     name: this.name,
            //     email: this.email,
            //     comment: this.comment,
            //     gdpr: this.gdpr,
            // });
            this.errors = {};
            this.formSubmitted = true;

            axios
                .post("/contact/save", {
                    name: this.name,
                    email: this.email,
                    comment: this.comment,
                    gdpr: this.gdpr,
                })
                .then((response) => {
                    console.log(response);

                    this.name = "";
                    this.email = "";
                    this.comment = "";
                    this.gdpr = "";
                    this.errors = {};
                    this.formSubmitted = false;

                    this.showAlertSuccess();
                })
                .catch((error) => {
                    this.errors = { ...error.response.data };
                    console.log("Error", error);

                    this.showAlertFailed();

                    this.formSubmitted = false;
                });
        },
        checkValidation(validationName) {
            return this.errors[validationName] !== undefined;
        },
        getError(validationName) {
            if (this.checkValidation(validationName)) {
                return this.errors[validationName].join(", ");
            }
        },
        showAlertSuccess() {
            this.saveSuccessful = true;
            setTimeout(() => {
                this.saveSuccessful = false;
            }, 3000);
        },
        showAlertFailed() {
            this.saveFailed = true;
            setTimeout(() => {
                this.saveFailed = false;
            }, 3000);
        },
    },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
