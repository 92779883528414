<template>
    <div :class="['draw-fireworks', { 'z-index-minus-1': animationDone }]">
        <canvas ref="fireworksCanvasRef"></canvas>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref, onBeforeUpdate } from "vue";
import { FireWorkService } from "../lib/fireworks/index";

export default {
    name: "draw-fireworks",
    props: {
        startAnimation: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const fireworksCanvasRef = ref(null);

        onBeforeUpdate(() => {
            fireworksCanvasRef.value = null;
        });

        return {
            fireworksCanvasRef,
        };
    },
    data() {
        return {
            animationDone: false,
            cycle: 0,
        };
    },
    mounted() {
        const canvas = this.fireworksCanvasRef;

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        let ctx = canvas.getContext("2d");

        const fireWorkService = new FireWorkService(canvas, ctx);
        let fireworkInterval = setInterval(() => {
            if (this.startAnimation) {
                if (this.cycle <= 2) {
                    for (let i = 0; i <= Math.random() * 15 + 5; i++) {
                        fireWorkService.addFirework();
                    }
                    this.cycle++;
                } else {
                    this.animationDone = true;
                    this.$emit("animationDone", this.animationDone);
                    // clearInterval(fireworkInterval);
                }
            }
        }, 2000);

        fireWorkService.startAnimate();

        window.addEventListener("resize", () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        });
    },
    methods: {},
    watch: {
        startAnimation() {
            this.animationDone = false;
            this.cycle = 0;
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", false);
    },
};
</script>

<style lang="scss">
.draw-fireworks {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4;
    pointer-events: none;
}

.z-index-minus-1 {
    // z-index: -1 !important;
}
</style>
