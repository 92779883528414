export class WhatStaticTexts {
    texts = [
        {
            icon: "images/v2/check_ring_round.svg",
            iconColor: "text-primary",
            content:
                "Minden promóciókban használt mechanizmust alkalmazhatsz: nyugta/számla feltöltés, promóciós kód. Regisztrációval vagy anélkül.",
        },
        {
            icon: "images/v2/Timer.svg",
            iconColor: "text-primary",
            content:
                "<b>Kivitelezési idő a megszokott töredéke alatt.</b> Nem szorulsz többé sarokba, ha későn érkezik egy jóváhagyás.",
        },
        {
            icon: "images/v2/Star.svg",
            iconColor: "text-primary",
            content:
                "<b>Kellene valami egyedi is egy kampányba?</b> Nálunk kérhetsz akár egyedi funkciókat is. A Prizific használata nem korlátoz be.",
        },
        {
            icon: "images/v2/Edit.svg",
            iconColor: "text-primary",
            content:
                "<b>Minden szöveg módosítható</b> adminból. Egy elgépelést 10 másodperc alatt javíthatsz.",
        },
        {
            icon: "images/v2/Setting_line.svg",
            iconColor: "text-primary",
            content: "<b>Egy admin</b> mind felett. Egy jelszó. Egyetlen.",
        },
        {
            icon: "images/v2/Fire.svg",
            iconColor: "text-primary",
            content:
                "Használhatod az <b>előre megírt hibaüzeneteket</b>, visszajelzéseket, emaileket, de akár módosíthatod is. Ahogy neked jobban fekszik.",
        },
        {
            icon: "images/v2/Lamp.svg",
            iconColor: "text-primary",
            content:
                "<b>Speciális szabályok</b>: pl. napi kódfeltöltési limit mértékkel fogyasztandó termékek esetén.",
        },
        {
            icon: "images/v2/Message_open.svg",
            iconColor: "text-primary",
            content:
                "<b>A nyertesekkel kezelése 1 klikk nyertesenként.</b> Az emailes kommunikáció teljesen automatizált személyre szabható emailekkel, exportálási lehetőségekkel. Ennél szinte már nem is lehet egyszerűbb.",
        },
        {
            icon: "images/v2/Dimond_alt.svg",
            iconColor: "text-primary",
            content:
                "Olyan előnyöket tudsz felvonultatni egy tenderen, <b>amelyekkel könnyű megkülönböztetni magad a versenytársaktól</b>.",
        },
    ];
}
