export class WhatStaticTexts {
    texts = [
        {
            icon: "images/v2/check_ring_round.svg",
            iconColor: "text-primary",
            content:
                "Minden promóciókban használt mechanizmust alkalmazhatsz: nyugta/számla feltöltés, promóciós kód. Regisztrációval vagy anélkül.",
        },
        {
            icon: "images/v2/Timer.svg",
            iconColor: "text-primary",
            content:
                "<strong>Kivitelezési idő a megszokott töredéke alatt.</strong> Nem szorulsz többé sarokba, ha későn érkezik egy jóváhagyás.",
        },
        {
            icon: "images/v2/Star.svg",
            iconColor: "text-primary",
            content:
                "<strong>Kellene valami egyedi is egy kampányba?</strong> Nálunk kérhetsz akár egyedi funkciókat is. A Prizific használata nem korlátoz be.",
        },
        {
            icon: "images/v2/Edit.svg",
            iconColor: "text-primary",
            content:
                "<strong>Minden szöveg módosítható</strong> adminból. Egy elgépelést 10 másodperc alatt javíthatsz.",
        },
        {
            icon: "images/v2/Setting_line.svg",
            iconColor: "text-primary",
            content:
                "<strong>Egy admin felületen</strong> keresztül menedzselheted az összes kampányod.",
        },
        {
            icon: "images/v2/Fire.svg",
            iconColor: "text-primary",
            content:
                "Használhatod az <strong>előre megírt visszajelzéseket,</strong> emaileket, vagy akár módosíthatod is. Ahogy neked jobban fekszik.",
        },
        {
            icon: "images/v2/Lamp.svg",
            iconColor: "text-primary",
            content:
                "<strong>Speciális szabályok</strong>: pl. napi kódfeltöltési limit mértékkel fogyasztandó termékek esetén.",
        },
        {
            icon: "images/v2/Message_open.svg",
            iconColor: "text-primary",
            content:
                "<strong>A nyertesek kezelése 1 klikk nyertesenként.</strong> A testre szabható emailes kommunikáció teljesen automatizált. Képes vagy exportálnia a nyerteseket és a pályázatokat. Ennél szinte már nem is lehet egyszerűbb.",
        },
        {
            icon: "images/v2/Dimond_alt.svg",
            iconColor: "text-primary",
            content:
                "Olyan előnyöket tudsz felvonultatni egy tenderen, <strong>amelyekkel könnyű megkülönböztetni magad a versenytársaktól</strong>.",
        },
    ];
}
