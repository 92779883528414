import { createStore } from "vuex";
import { drawStore } from "./modules/DrawStore";
import { cookieSettingsStore } from "./modules/CookieSettingsStore";

const store = createStore({
    modules: {
        drawStore,
        cookieSettingsStore,
    },
});

export default store;
