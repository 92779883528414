const cookie = require("js-cookie");

export const cookieSettingsStore = {
    namespaced: true,

    state: () => ({
        cookieConsent: {
            statistics: false,
            marketing: false,
            show: false,
        },
        activePage: "intro",
    }),

    getters: {
        /**
         * Get client cookie
         *
         * @returns {boolean}
         */
        getCookieConsent: (state) => {
            return state.cookieConsent;
        },

        /**
         * Get dialog active page
         *
         * @returns {boolean}
         */
        getCookieConsentActivePage: (state) => {
            return state.activePage;
        },
    },

    actions: {},

    mutations: {
        /**
         * Set cookie consent
         *
         * @param {*} state
         * @param string cookiesString required only for cookie-consent middleware (server)
         */
        setCookieConsent(state, cookiesString) {
            return new Promise(() => {
                try {
                    if (cookiesString) {
                        state.cookieConsent = JSON.parse(cookiesString);
                    } else if (cookie.get("prizific-cookie-consent")?.length) {
                        state.cookieConsent = JSON.parse(
                            cookie.get("prizific-cookie-consent")
                        );
                    } else {
                        state.cookieConsent.show = true;
                    }

                    if (state.cookieConsent.statistics) {
                        // this.$pgtag.enable();
                    } else {
                        // this.$pgtag.disable();
                    }
                } catch (e) {
                    console.error("setCookieConsent", e);

                    state.cookieConsent.show = true;
                }
            });
        },

        /**
         * Set active page
         *
         * @param {} state
         * @param {*} page
         */
        setActivePage(state, page) {
            state.activePage = page;
        },

        /**
         * Set all cookie true and saving in cookie
         * @param {*} state
         */
        approveAllCookie(state) {
            state.cookieConsent.statistics = true;
            state.cookieConsent.marketing = true;
            state.cookieConsent.show = false;

            cookie.set(
                "prizific-cookie-consent",
                JSON.stringify({
                    statistics: true,
                    marketing: true,
                    show: false,
                }),
                {
                    expires: 365,
                }
            );

            setTimeout(() => {
                location.reload();
            }, 500);
        },

        /**
         * Set selected cookies true and saving in cookie
         * @param {*} state
         */
        approveSelectedCookies(state, selectedCookies) {
            state.cookieConsent.show = false;

            cookie.set(
                "prizific-cookie-consent",
                JSON.stringify({
                    statistics: selectedCookies.statistics,
                    marketing: selectedCookies.marketing,
                    show: false,
                }),
                {
                    expires: 365,
                }
            );
            setTimeout(() => {
                location.reload();
            }, 500);
        },

        /**
         * Show cookie dialog
         * @param {*} state
         */
        showCookieDialog(state) {
            state.cookieConsent.show = true;
        },

        /**
         * Close cookie dialog
         * @param {*} state
         */
        closeCookieDialog(state) {
            state.cookieConsent.show = false;
        },

        /**
         * Set google analytics
         */
        setAnalytics() {
            let prizificCookieConsent;
            if (cookie.get("prizific-cookie-consent")) {
                prizificCookieConsent = JSON.parse(
                    cookie.get("prizific-cookie-consent")
                );
            }

            if (
                prizificCookieConsent !== undefined &&
                prizificCookieConsent.statistics
            ) {
                console.log("GOOGLE_ANALYTICS_TRACKING_ENABLE");
                location.reload();
            } else {
                console.log("GOOGLE_ANALYTICS_TRACKING_DISABLE");
                location.reload();
            }
        },
    },
};
