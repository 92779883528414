<template>
    <div ref="navbarWrapperRef" class="navbar-default sticky-top">
        <div class="d-none d-lg-block container">
            <NavbarInner
                :shadow="navbarShadow"
                :logoSrc="logoSrc"
                :menuItems="menuItems"
            />
        </div>
        <div class="d-block d-lg-none">
            <NavbarInner
                :shadow="navbarShadow"
                :logoSrc="logoSrc"
                :menuItems="menuItems"
            />
        </div>
    </div>
</template>

<script>
import NavbarInner from "./NavbarInner.vue";
export default {
    name: "navbar-component",
    components: {
        NavbarInner,
    },
    props: {
        logoSrc: {
            type: String,
            required: false,
        },
        menuItems: {
            required: false,
            default: [
                {
                    name: "LAKOSSÁGI",
                    link: "https://prizific.com",
                    active: false,
                },
                {
                    name: "FMCG",
                    link: "https://b2b.prizific.com",
                    active: true,
                },
                {
                    name: "ÜGYNÖKSÉGI",
                    link: "https://b2b.prizific.com/ugynoksegek",
                    active: false,
                },
            ],
        },
    },
    data() {
        return {
            navbarShadow: false,
            currentScrollPosition: 0,
            lastScrollPosition: 0,
            threshold: 0,
            scrollDifference: 0,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            const navbarWrapperRef = this.$refs.navbarWrapperRef;

            this.currentScrollPosition = window.scrollY;
            this.scrollDifference =
                this.currentScrollPosition - this.lastScrollPosition;

            if (
                this.currentScrollPosition >= 0 &&
                this.currentScrollPosition <= 28
            ) {
                navbarWrapperRef.classList.remove("show");
                this.navbarShadow = false;
            } else if (this.scrollDifference < -this.threshold) {
                navbarWrapperRef.classList.add("show");
                this.navbarShadow = true;
            } else if (this.scrollDifference > this.threshold) {
                navbarWrapperRef.classList.remove("show");
                this.navbarShadow = false;
            }

            this.lastScrollPosition = this.currentScrollPosition;
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-primary {
    font-size: 14px;
    font-weight: 600;
}

.navbar-default {
    top: -64px;
    transition: top 0.3s ease-in-out;

    &.show {
        top: 0 !important;
        transition: top 0.3s ease-in-out;
    }

    @media (min-width: 992px) {
        &.show {
            top: 8px !important;
        }
    }
}

.navbar {
    border-radius: 13px;
    padding: 8px 10px;

    .navbar-brand {
        padding: 0;
        margin-left: 8px;
    }

    .nav-item {
        font-weight: bold;

        .nav-link {
            padding: 8px 24px;
            color: black;

            &:hover {
                color: #e44f60;
            }
        }
    }
}

.page-navigation {
    .page-navigation-link {
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;

        &:hover {
            color: #e44f60;
            text-decoration: underline;
        }

        &.active {
            color: #e44f60;
            text-decoration: underline;
        }
    }
}
</style>
