"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Particle = exports.Firework = exports.FireWorkService = void 0;
var AnimationState;
(function (AnimationState) {
    AnimationState["Stopped"] = "stopped";
    AnimationState["Running"] = "running";
    AnimationState["Default"] = "default";
})(AnimationState || (AnimationState = {}));
class FireWorkService {
    constructor(canvas, ctx) {
        this.fireworkArray = [];
        this.particleArray = [];
        this.canvas = canvas;
        this.ctx = ctx;
        this.state = AnimationState.Default;
    }
    startAnimate() {
        if (this.state === AnimationState.Default ||
            this.state === AnimationState.Running) {
            requestAnimationFrame(() => {
                this.startAnimate();
            });
        }
        //this.ctx.fillStyle = "rgba(75,75,75,.02)";
        /*let gradientBg = this.ctx.createRadialGradient(
            this.canvas.width / 2,
            this.canvas.height / 2,
            this.canvas.width / 2,
            this.canvas.width / 2,
            this.canvas.height / 2,
            70
        );
        gradientBg.addColorStop(0, "rgba(249,195,87,.35)");
        gradientBg.addColorStop(1, "rgba(255,214,144,.35)");
        
        this.ctx.fillStyle = gradientBg;*/
        //this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.fireworkArray.forEach((firework) => {
            firework.update(this.ctx);
            if (firework.boom && firework.boomDone == false) {
                for (var i = 0; i < Math.random() * 50 + 25; i++) {
                    this.particleArray.push(new Particle(firework.x, firework.y));
                }
                firework.boomDone = true;
            }
        });
        this.fireworkArray = this.fireworkArray.filter((obj) => !obj.animationDone);
        this.particleArray.forEach((particle) => {
            particle.update(this.ctx);
        });
        this.particleArray = this.particleArray.filter((obj) => !obj.fade);
    }
    stopAnimate() {
        this.state = AnimationState.Stopped;
    }
    addFirework() {
        var randomLeft = (Math.random() * this.canvas.height) / 3 + this.canvas.height / 4; //TODO: állítsunk be neki egy szép számot!
        var randomRight = (Math.random() * this.canvas.width) / 3 + this.canvas.width / 3;
        var angle = Math.atan2(randomLeft - this.canvas.height, randomRight - this.canvas.width / 2);
        var dx = Math.cos(angle);
        var dy = Math.sin(-angle);
        this.fireworkArray.push(new Firework(this.canvas.width / 2, this.canvas.height, dx, dy, randomLeft));
    }
}
exports.FireWorkService = FireWorkService;
/**
 * Represent a firework,
 */
class Firework {
    constructor(x, y, dx, dy, boomHeight) {
        this.boomDone = false;
        this.particleArray = [];
        this.oldPositions = [];
        this.maxPositionsLength = 5;
        this.x = x;
        this.y = y;
        this.boomHeight = boomHeight;
        this.boom = false;
        this.dx = dx;
        this.dy = dy;
        //this.update();
    }
    update(ctx) {
        let oldPositionLeft = this.maxPositionsLength - this.oldPositions.length;
        if (oldPositionLeft > 0 && this.boom == false) {
            this.oldPositions.push({
                x: this.x,
                y: this.y,
            });
        }
        if (oldPositionLeft <= 1 || this.boom == true) {
            this.oldPositions.shift();
        }
        if (this.boom == true && this.oldPositions.length == 0) {
            this.animationDone = true;
        }
        this.x += this.dx * 5;
        this.y -= this.dy * 5;
        if (this.y < this.boomHeight) {
            this.boom = true;
        }
        this.draw(ctx);
    }
    draw(ctx) {
        ctx.save();
        this.oldPositions.forEach((position, index) => {
            let opacity = (index + 1) / this.oldPositions.length;
            ctx.fillStyle = "rgba(255,255,255," + opacity + ")";
            ctx.beginPath();
            ctx.arc(position.x, position.y, 3, Math.PI * 2, 0, false);
            ctx.fill();
        });
        if (this.boom == false) {
            ctx.fillStyle = "white";
            ctx.beginPath();
            ctx.arc(this.x, this.y, 3, Math.PI * 2, 0, false);
            ctx.fill();
        }
        ctx.restore();
    }
    remove() { }
}
exports.Firework = Firework;
class Particle {
    constructor(x, y) {
        this.animationDone = false;
        this.oldPositions = [];
        this.maxPositionsLength = 5;
        let colors = [
            "rgba(255,255,255",
            "rgba(91,230,123",
            "rgba(4,243,60",
            "rgba(4,211,243",
            "rgba(102,204,255",
            "rgba(102,133,255",
            "rgba(228,79,96",
        ];
        this.x = x;
        this.y = y;
        this.alpha = 0.7;
        this.fade = false;
        this.color = colors[Math.floor(Math.random() * colors.length)];
        this.radius = Math.random() * 2 + 1;
        this.dx = Math.random() * 10 - 4;
        this.dy = Math.random() * 10 - 5;
        //this.update();
    }
    draw(ctx) {
        ctx.save();
        this.oldPositions.forEach((position, index) => {
            ctx.fillStyle = this.color + "," + position.alpha + ")";
            ctx.globalCompositeOperation = "lighter";
            ctx.beginPath();
            ctx.arc(position.x, position.y, this.radius, Math.PI * 2.3, 0, false);
            ctx.fill();
        });
        ctx.restore();
    }
    update(ctx) {
        let oldPositionLeft = this.maxPositionsLength - this.oldPositions.length;
        if (oldPositionLeft > 0) {
            this.oldPositions.push({
                x: this.x,
                y: this.y,
                alpha: this.alpha,
            });
        }
        if (oldPositionLeft <= 1) {
            this.oldPositions.shift();
        }
        if (this.oldPositions.length == 0) {
            this.animationDone = true;
        }
        this.x += this.dx;
        this.y += this.dy;
        this.dy += 0.05;
        this.alpha = this.alpha - 0.02;
        if (this.alpha < 0) {
            this.fade = true;
        }
        this.draw(ctx);
    }
}
exports.Particle = Particle;
