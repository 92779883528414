<template>
    <div>
        <nav
            ref="navbarRef"
            id="navbar"
            :class="['navbar navbar-expand-lg', { shadow: shadow }]"
        >
            <a
                class="navbar-brand d-flex align-items-center user-select-none"
                href="/"
            >
                <img width="156" height="22" :src="logoSrc" alt="Prizific" />
            </a>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 me-0 me-lg-2">
                    <li class="nav-item">
                        <a class="nav-link" href="/ugynoksegek">
                            Ügynökségek
                        </a>
                    </li>
                </ul>
            </div>
            <a class="btn btn-primary d-none d-lg-block" href="/#contact">
                Beszélgessünk
            </a>
            <MobileNavItem
                className="d-lg-none"
                @click="showMenu"
                @click.native="setActiveMenu('menu')"
                icon="menu"
                name=""
            />
        </nav>
        <transition name="menu">
            <div
                v-if="menuState"
                class="mobile-navbar-menu d-flex flex-column d-lg-none align-items-center pt-3 pb-6"
            >
                <div
                    class="w-100 d-flex flex-row gap-2 justify-content-between"
                >
                    <a
                        class="d-flex align-items-center user-select-none ms-4"
                        href="/"
                    >
                        <img
                            class="prizific-logo-mobile"
                            width="156"
                            height="22"
                            :src="logoSrc"
                            alt="Prizific"
                        />
                    </a>
                    <div class="mobile-navbar-menu-close">
                        <span @click="hideMenu">
                            <span @click="hideMenu">
                                <img
                                    src="/images/v2/mobile_menu_close.svg"
                                    alt=""
                                />
                            </span>
                        </span>
                    </div>
                </div>
                <div class="social-medias-mobile">
                    <a href="https://www.facebook.com/prizific" target="_blank">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/prizific"
                        target="_blank"
                    >
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                </div>
                <ul class="menu-list w-100 mt-5">
                    <li>
                        <a href="https://b2b.prizific.com/ugynoksegek"
                            >Ügynökségek</a
                        >
                    </li>
                </ul>
                <div class="contact-button-wrapper w-100">
                    <a
                        class="btn btn-outline-primary py-0"
                        href="/#contact"
                        @click="hideMenu"
                    >
                        Beszélgessünk
                    </a>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import NavbarSecondaryMobile from "./NavbarSecondaryMobile.vue";
import MobileNavItem from "./MobileNavItem.vue";

export default {
    name: "navbar-inner-component",
    components: {
        NavbarSecondaryMobile,
        MobileNavItem,
    },
    props: {
        shadow: {
            type: Boolean,
            required: false,
            default: false,
        },
        logoSrc: {
            type: String,
            required: false,
        },
        menuItems: {
            required: false,
            default: [
                {
                    name: "LAKOSSÁGI",
                    link: "https://prizific.com",
                    active: false,
                },
                {
                    name: "FMCG",
                    link: "https://b2b.prizific.com",
                    active: true,
                },
                {
                    name: "ÜGYNÖKSÉGI",
                    link: "https://b2b.prizific.com/ugynoksegek",
                    active: false,
                },
            ],
        },
    },
    data() {
        return {
            activeMenu: "",
            menuState: false,
        };
    },
    mounted() {
        window.addEventListener("resize", this.isMobileSize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.isMobileSize);
    },
    methods: {
        showMenu() {
            this.disableScroll();

            this.menuState = true;
        },
        hideMenu() {
            this.enableScroll();

            this.menuState = false;
        },
        /**
         * Set active menu
         */
        setActiveMenu(name) {
            this.activeMenu = name;
        },
        whichMenuIsActive(menu) {
            if (this.activeMenu !== menu) {
                return "";
            } else {
                return "active";
            }
        },
        disableScroll() {
            document.body.style.overflow = "hidden";
        },
        enableScroll() {
            document.body.style.overflow = "";
        },
        isMobileSize() {
            if (window.innerWidth > 991) {
                if (this.menuState) {
                    this.hideMenu;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-primary {
    font-size: 20px;
    line-height: 27px;
    font-weight: 800;
    border-radius: 32px;
    padding: 0px 24px;
}

.prizific-logo-mobile {
    z-index: 99999;
}

.navbar {
    margin-top: 0;
    border-radius: 0 !important;
    padding: 4px 10px 4px 16px;

    .navbar-brand {
        padding: 0;
        margin-left: 8px;
    }

    .nav-item {
        font-size: 20px;
        line-height: 27px;
        font-weight: 800;

        .nav-link {
            padding: 8px 24px;
            color: rgba(255, 255, 255, 0.7);

            &:hover {
                color: white;
            }
        }
    }

    @media (min-width: 992px) {
        margin-top: 24px;
        border-radius: 48px !important;
    }
}

.navbar-default {
    .navbar {
        transition: background-color 0.15s ease-in-out,
            backdrop-filter 0.15s ease-in-out;
    }

    &.show {
        .navbar {
            background-color: rgba(255, 255, 255, 0.16) !important;
            backdrop-filter: saturate(180%) blur(20px);
            border: 0;
        }
    }
}

.mobile-navbar {
    z-index: 9999;
    border-top: 1px solid #cecece;
    background-color: white;
}
.mobile-navbar-menu {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-color: #0e233b !important;
    background-image: url("/images/v2/mobile_menu_bg.webp");
    transform: translateY(0);
    transition: transform 0.45s;

    a {
        text-decoration: none;
        font-weight: bold;
        color: white;
        font-size: 16px;
        padding: 0.5rem 0.25rem;
    }

    a.nuxt-link-exact-active {
        color: white;
    }

    .menu-list {
        font-size: 24px;
        font-weight: bold;
        padding-left: 64px;
        list-style-type: none;
        text-align: left;

        li {
            padding: 20px 0;

            a {
                font-size: 24px;
            }
        }
    }

    h3 {
        color: white;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 1.5rem;
    }

    .mobile-navbar-menu-item {
        text-decoration: none;
        font-weight: 500 !important;
        color: white;
        font-size: 16px;
        padding: 0.5rem 0.25rem;
    }

    .mobile-navbar-menu-close {
        width: 100%;
        margin-right: 1rem;
        text-align: right;
        cursor: pointer;

        .material-icons-outlined {
            font-size: 32px;
            color: white;
        }
    }

    .contact-button-wrapper {
        margin-left: 134px;
        text-align: left;
        margin-top: auto;
        margin-bottom: 214px;

        a {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .social-medias-mobile {
        position: absolute;
        top: 35%;
        left: 16px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        gap: 8px;

        a {
            font-size: 24px;
        }
    }

    .btn-outline-primary {
        box-shadow: 0px 0px 7px 0px #f8fbf4cc;

        &:hover {
            color: black;
        }
        &:focus {
            color: black;
        }
    }
}

.menu-enter-active,
.menu-leave-active {
    transition: transform 0.45s;
    z-index: 9998;
    transform: translateY(0);
}
.menu-enter,
.menu-leave-to {
    z-index: 9998;
    transform: translateY(-200%);
}
</style>
