<template>
    <div
        ref="cookieModalRef"
        class="modal"
        tabindex="-1"
        data-bs-backdrop="false"
        data-bs-keyboard="false"
    >
        <div class="backdrop"></div>
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <template v-for="(page, index) in pages">
                        <div
                            v-if="page.name === activePage"
                            class="d-flex justify-content-center align-items-center w-100"
                            :key="index"
                        >
                            <div
                                v-if="page.navigate.length"
                                @click="setActivePage(page.navigate)"
                                class="d-flex me-auto fs-4 page-navigation"
                            >
                                <span class="material-icons">
                                    chevron_left
                                </span>
                            </div>
                            <h3
                                :class="[
                                    'text-center px-4 py-2 fw-bold me-auto mb-0',
                                    {
                                        'ms-auto': activePage == 'intro',
                                    },
                                ]"
                                v-html="page.title"
                            ></h3>
                        </div>
                    </template>
                </div>
                <div class="modal-body">
                    <div v-if="activePage == 'intro'" class="mt-3">
                        <div class="fw-bold fs-6">
                            Ezen a weboldalon sütiket használunk a felhasználói
                            élmény javítása, a hirdetések testreszabása valamint
                            a navigáció megkönnyítése érdekében.
                        </div>
                        <div class="fw-bold fs-6 mt-2">
                            Bővebb információ itt:
                            <a
                                class="cursor-pointer text-decoration-underline"
                                href="/doc/cookie-consent.pdf"
                                target="_blank"
                            >
                                Cookie szabályzat
                            </a>
                        </div>
                        <div
                            class="d-flex align-items-center justify-content-end flex-column flex-md-row gap-4 mt-5"
                        >
                            <button
                                class="btn btn-text"
                                @click="setActivePage('settings')"
                            >
                                Beállítások
                            </button>
                            <button
                                class="btn btn-primary"
                                @click="approveAllCookie()"
                            >
                                Összes elfogadása
                            </button>
                        </div>
                    </div>

                    <div
                        v-if="activePage == 'settings'"
                        class="d-flex flex-column gap-3 mt-3"
                    >
                        <div class="d-flex align-items-center gap-2">
                            <div class="fw-bold fs-6">
                                Alapvető, működéshez szükséges sütik
                            </div>
                            <div class="ms-auto fs-6">Mindig aktív</div>
                        </div>
                        <div class="d-flex align-items-center gap-2">
                            <div class="fw-bold fs-6">Statisztikai sütik</div>
                            <div class="form-check form-switch ms-auto">
                                <input
                                    v-model="cookieSettings.statistics"
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                />
                            </div>
                        </div>
                        <div class="d-flex align-items-center gap-2">
                            <div class="fw-bold fs-6">Marketing sütik</div>
                            <div class="form-check form-switch ms-auto">
                                <input
                                    v-model="cookieSettings.marketing"
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                />
                            </div>
                        </div>
                        <div class="fw-bold fs-6">
                            Bővebb információ itt:
                            <a
                                class="cursor-pointer text-decoration-underline"
                                href="/doc/cookie-consent.pdf"
                                target="_blank"
                            >
                                Cookie szabályzat
                            </a>
                        </div>
                        <div
                            class="d-flex align-items-center justify-content-end flex-column flex-md-row mt-5 gap-4"
                        >
                            <button
                                class="btn btn-text"
                                @click="approveSelectedCookies(cookieSettings)"
                            >
                                Kiválasztottak elfogadása
                            </button>
                            <button
                                class="btn btn-primary"
                                @click="approveAllCookie()"
                            >
                                Összes elfogadása
                            </button>
                        </div>
                    </div>

                    <div v-if="activePage == 'consent'">
                        <div class="cookie-consent"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import { mapGetters, mapMutations } from "vuex";
const cookie = require("js-cookie");

export default {
    name: "cookie-settings-modal",
    data() {
        return {
            modal: null,
            cookieSettings: {},
            pages: [
                { title: "Cookie beállítások", name: "intro", navigate: "" },
                {
                    title: "Cookie beállítások",
                    name: "settings",
                    navigate: "intro",
                },
                {
                    title: "Cookie tájékoztató",
                    name: "consent",
                    navigate: "settings",
                },
            ],
        };
    },
    mounted() {
        this.setCookieConsent();

        try {
            this.cookieSettings = JSON.parse(
                JSON.stringify(this.cookieConsent)
            );
        } catch (err) {
            console.error(err);
        }

        this.modal = new Modal(this.$refs.cookieModalRef);
    },
    computed: {
        ...mapGetters({
            cookieConsent: "cookieSettingsStore/getCookieConsent",
            activePage: "cookieSettingsStore/getCookieConsentActivePage",
        }),
    },

    methods: {
        ...mapMutations({
            setAnalytics: "cookieSettingsStore/setAnalytics",
            setActivePage: "cookieSettingsStore/setActivePage",
            setCookieConsent: "cookieSettingsStore/setCookieConsent",
            approveAllCookie: "cookieSettingsStore/approveAllCookie",
            closeCookieDialog: "cookieSettingsStore/closeCookieDialog",
            approveSelectedCookies:
                "cookieSettingsStore/approveSelectedCookies",
        }),

        openCookieModal() {
            this.modal.show();
        },

        closeCookieModal() {
            this.modal.hide();
        },
    },
    watch: {
        cookieConsent: {
            handler: function (newVal) {
                this.cookieSettings = newVal;

                if (newVal?.show) {
                    this.openCookieModal();
                } else {
                    this.closeCookieModal();
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import "../../scss/variables";

.modal {
    &.show {
        .backdrop {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.35);
        }
    }

    .modal-header {
        border: 0;
        padding: 2rem 1rem 1rem 1rem;

        h3 {
            font-size: 24px;
            color: #f8fbf4;
        }

        @media (min-width: 576px) {
            padding: 4rem 4rem 1rem 4rem;
        }
    }

    .modal-content {
        border: 0;
        border-radius: 32px;
    }

    .page-navigation {
        cursor: pointer;
    }

    .modal-body {
        padding: 1rem 1rem 2rem 1rem;

        a {
            color: #f8fbf4;

            &:hover {
                color: #f8fbf4;
            }
        }

        .btn-text {
            color: $primary;
        }

        .btn-primary {
            font-weight: bold;
        }

        .form-check-input {
            width: 48px;
            height: 20px;
            cursor: pointer;
            border: 1px solid #166681;
            background-color: #166681;
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{white}'/></svg>");
        }

        .form-check-input:focus {
            box-shadow: none;
            border: 1px solid #166681;
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{white}'/></svg>");
        }

        .form-check-input:checked {
            background-color: #13ce66;
            border: 1px solid #13ce66;
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{white}'/></svg>");

            &:focus {
                border: 1px solid #13ce66;
            }
        }

        @media (min-width: 576px) {
            padding: 1rem 4rem 4rem 4rem;
        }
    }
}
</style>
