<template>
    <div
        ref="sectionTitleRef"
        :class="[
            'section-title',
            animationClass,
            { 'text-center': center },
            { 'ms-auto me-auto': positionCenter },
        ]"
    >
        <div class="section-title-long">
            {{ description }}
        </div>
        <h2>
            {{ title }}
        </h2>
    </div>
</template>

<script>
import ScrollMagic from "scrollmagic";

export default {
    name: "section-title",
    props: {
        title: String,
        description: String,
        animationClass: String,
        center: {
            type: Boolean,
            default: false,
        },
        positionCenter: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            animated: false,
        };
    },
    mounted() {
        // Elements
        const sectionTitle = this.$refs.sectionTitleRef;

        // Scroll magic
        const controller = new ScrollMagic.Controller();

        const scene = new ScrollMagic.Scene({
            triggerHook: 0.8,
            triggerElement: sectionTitle,
        })
            .setClassToggle(`.${this.animationClass}`, "fade-in-left")
            .reverse(false)
            .addTo(controller);
    },
};
</script>
