<template>
    <div>
        <nav class="mobile-navbar fixed-bottom py-2 d-block d-lg-none">
            <div class="container-fluid">
                <div class="d-flex justify-content-around">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <MobileNavItem
                        :className="whichMenuIsActive('menu')"
                        @click="showMenu"
                        @click.native="setActiveMenu('menu')"
                        icon="menu"
                        name="Menü"
                    />
                </div>
            </div>
        </nav>

        <transition name="menu">
            <div
                v-if="menuState"
                class="mobile-navbar-menu d-flex flex-column d-lg-none align-items-center py-4"
            >
                <div class="mobile-navbar-menu-close">
                    <span @click="hideMenu">
                        <img src="/images/v2/mobile_menu_close.svg" alt="" />
                    </span>
                </div>
                <h3>Menü</h3>
                <!-- <div class="mt-auto">
                    <NavbarSecondaryMobile
                        :menuItems="
                            typeof menuItems == 'string'
                                ? JSON.parse(menuItems)
                                : menuItems
                        "
                    />
                </div> -->
            </div>
        </transition>
    </div>
</template>

<script>
import NavbarSecondaryMobile from "./NavbarSecondaryMobile.vue";
import MobileNavItem from "./MobileNavItem.vue";

export default {
    name: "mobile-navbar-component",
    components: {
        NavbarSecondaryMobile,
        MobileNavItem,
    },
    props: {
        menuItems: {
            required: false,
            default: [
                {
                    name: "LAKOSSÁGI",
                    link: "https://prizific.com",
                    active: false,
                },
                {
                    name: "FMCG",
                    link: "https://b2b.prizific.com",
                    active: true,
                },
                {
                    name: "ÜGYNÖKSÉGI",
                    link: "https://b2b.prizific.com/ugynoksegek",
                    active: false,
                },
            ],
        },
    },
    data() {
        return {
            activeMenu: "",
            menuState: false,
        };
    },
    mounted() {
        window.addEventListener("resize", this.isMobileSize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.isMobileSize);
    },
    methods: {
        showMenu() {
            this.disableScroll();

            this.menuState = true;
        },
        hideMenu() {
            this.enableScroll();

            this.menuState = false;
        },
        /**
         * Set active menu
         */
        setActiveMenu(name) {
            this.activeMenu = name;
        },
        whichMenuIsActive(menu) {
            if (this.activeMenu !== menu) {
                return "";
            } else {
                return "active";
            }
        },
        disableScroll() {
            document.body.style.overflow = "hidden";
        },
        enableScroll() {
            document.body.style.overflow = "";
        },
        isMobileSize() {
            if (window.innerWidth > 991) {
                if (this.menuState) {
                    this.hideMenu;
                }
            }
        },
    },
};
</script>

<style lang="scss">
.mobile-navbar {
    z-index: 9999;
    border-top: 1px solid #cecece;
    background-color: white;
}
.mobile-navbar-menu {
    position: fixed;
    z-index: 10000;
    top: 0;
    // top: 47px;
    left: 0;
    bottom: 0;
    // bottom: 64px;
    width: 100vw;
    max-width: 100vw;
    height: calc(100% - 67px);
    background-size: 100%;
    background-color: #0e233b !important;
    background-image: url("/images/v2/mobile_menu_bg.webp");

    a {
        text-decoration: none;
        font-weight: bold;
        color: #e44f60;
        font-size: 16px;
        padding: 0.5rem 0.25rem;
    }

    a.nuxt-link-exact-active {
        color: #e44f60;
    }

    h3 {
        color: #e44f60;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 1.5rem;
    }

    .mobile-navbar-menu-item {
        text-decoration: none;
        font-weight: 500 !important;
        color: #e44f60;
        font-size: 16px;
        padding: 0.5rem 0.25rem;
    }

    .mobile-navbar-menu-close {
        width: 100%;
        margin-right: 2rem;
        text-align: right;
        cursor: pointer;

        .material-icons-outlined {
            font-size: 32px;
            color: #e44f60;
        }
    }
}

.menu-enter-active,
.menu-leave-active {
    transition: transform 0.45s;
    z-index: 9998;
    transform: translateY(0);
}
.menu-enter,
.menu-leave-to {
    z-index: 9998;
    transform: translateY(200%);
}
</style>
