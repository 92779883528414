export class TheySaidStaticTexts {
    texts = [
        {
            content: "Ez egy űrhajó a korábbi rendszerekhez képest!",
            name: "Makra Mercédesz",
            schedule: "account director, Rewart",
        },
        {
            content: "Imádom ezeket az okos programokat!",
            name: "Vágvölgyi Zsófia",
            schedule: "account manager, Deepinsight",
        },
    ];
}
