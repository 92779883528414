<template>
    <div class="tabs">
        <div class="d-flex">
            <div
                class="tab-navigation ms-auto me-auto mb-6 d-flex flex-wrap justify-content-center align-items-center gap-3"
            >
                <div
                    :class="[
                        'btn btn-secondary-outline',
                        { active: activeButton == 'promo' },
                    ]"
                    @click="setActiveTab('promo')"
                >
                    Promo sites
                </div>
                <div
                    :class="[
                        'btn btn-secondary-outline',
                        { active: activeButton == 'hive' },
                    ]"
                    @click="setActiveTab('hive')"
                >
                    Kaptár
                </div>
            </div>
        </div>

        <div v-if="activeButton == 'promo'">
            <slot name="promo"></slot>
        </div>
        <div v-if="activeButton == 'hive'">
            <slot name="hive"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "tab-component",
    props: {},
    data() {
        return {
            activeButton: "promo",
        };
    },
    mounted() {},
    methods: {
        setActiveTab(tabName) {
            this.activeButton = tabName;
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-secondary-outline {
    padding: 2px 24px;
    min-width: 224px;
    color: white;
    border-radius: 32px;
    margin-right: 16px;
    border: 2px solid white;
    font-size: 20px;
    font-weight: 800;
    line-height: 27px;
    background-color: transparent;

    &:hover {
        background-color: white;
        color: black;
    }

    &.active {
        color: black;
        background-color: white;
        border: 2px solid white;

        &:focus {
            color: black;
            background-color: white;
        }
    }

    &:focus {
        color: black;
        background-color: white;
        border: 2px solid white;
    }

    &:active {
        color: black;
        background-color: white;
        border: 2px solid white;

        &:focus {
            color: black;
            background-color: white;
            border: 2px solid white;
        }
    }
}
</style>
