export class WhatStaticTexts {
    texts = [
        {
            icon: "all_inclusive",
            iconColor: "text-third",
            content:
                "Minden promóciókban használt mechanizmust alkalmazhatsz: nyugta/számla feltöltés, promóciós kód. Regisztrációval vagy anélkül.",
        },
        {
            icon: "timer",
            iconColor: "text-secondary",
            content:
                "<b>Kivitelezési idő a megszokott töredéke alatt.</b> Nem szorulsz többé sarokba, ha későn érkezik egy jóváhagyás.",
        },
        {
            icon: "stars",
            iconColor: "text-secondary",
            content:
                "<b>Kellene valami egyedi is egy kampányba?</b> Nálunk kérhetsz akár egyedi funkciókat is. A Prizific használata nem korlátoz be.",
        },
        {
            icon: "edit",
            iconColor: "text-secondary",
            content:
                "<b>Minden szöveg módosítható</b> adminból. Egy elgépelést 10 másodperc alatt javíthatsz.",
        },
        {
            icon: "circle",
            iconColor: "text-third",
            content: "<b>Egy admin</b> mind felett. Egy jelszó. Egyetlen.",
        },
        {
            icon: "whatshot",
            iconColor: "text-secondary",
            content:
                "Használhatod az <b>előre megírt hibaüzeneteket</b>, visszajelzéseket, emaileket, de akár módosíthatod is. Ahogy neked jobban fekszik.",
        },
        {
            icon: "emoji_objects",
            iconColor: "text-secondary",
            content:
                "<b>Speciális szabályok</b>: pl. napi kódfeltöltési limit mértékkel fogyasztandó termékek esetén.",
        },
        {
            icon: "email",
            iconColor: "text-secondary",
            content:
                "<b>A nyertesekkel kezelése 1 klikk nyertesenként.</b> Az emailes kommunikáció teljesen automatizált személyre szabható emailekkel, exportálási lehetőségekkel. Ennél szinte már nem is lehet egyszerűbb.",
        },
        {
            icon: "plus_one",
            iconColor: "text-third",
            content:
                "Olyan előnyöket tudsz felvonultatni egy tenderen, <b>amelyekkel könnyű megkülönböztetni magad a versenytársaktól</b>.",
        },
    ];
}
