<template>
    <div class="d-none d-md-flex row px-0">
        <template v-for="(item, index) in list" :key="index">
            <template v-if="index === 0">
                <div class="col-12 col-md-4 offset-md-2">
                    <benefits-list-item-component
                        :title="item.title"
                        :description="item.description"
                        :number="item.number"
                        :animation-class="item.animationClass"
                    >
                    </benefits-list-item-component>
                </div>
                <div class="col-12 col-md-4 d-none d-md-flex">
                    <img
                        class="img-fluid mt-auto mb-auto"
                        src="/images/v2/promo-sites.svg"
                    />
                </div>
            </template>
            <template v-else>
                <div
                    :class="[
                        'col-12 col-md-4',
                        {
                            'mb-12 mb-md-6 mb-md-0': index === index.length - 1,
                        },
                    ]"
                >
                    <benefits-list-item-component
                        :title="item.title"
                        :description="item.description"
                        :number="item.number"
                        :animation-class="item.animationClass"
                    >
                    </benefits-list-item-component>
                </div>
            </template>
        </template>
    </div>
    <div class="d-block d-md-none row px-0">
        <template v-for="(item, index) in list" :key="index">
            <template v-if="index < 3">
                <div class="col-12 col-md-4 offset-md-2">
                    <benefits-list-item-component
                        :title="item.title"
                        :description="item.description"
                        :number="item.number"
                        :animation-class="item.animationClass"
                    >
                    </benefits-list-item-component>
                </div>
            </template>
        </template>
        <div class="collapse" id="collapseExample">
            <template v-for="(item, index) in list" :key="index">
                <template v-if="index >= 3">
                    <div
                        :class="[
                            'col-12 col-md-4',
                            {
                                'mb-12 mb-md-6 mb-md-0':
                                    index === index.length - 1,
                            },
                        ]"
                    >
                        <benefits-list-item-component
                            :title="item.title"
                            :description="item.description"
                            :number="item.number"
                            :animation-class="item.animationClass"
                        >
                        </benefits-list-item-component>
                    </div>
                </template>
            </template>
        </div>
        <div class="col-12 mb-12 mb-md-6 mb-md-0 text-center">
            <button
                class="btn btn-more ms-auto me-auto gap-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
            >
                További előnyök
                <span class="up material-icons">keyboard_arrow_up</span>
                <span class="down material-icons">keyboard_arrow_down</span>
            </button>
        </div>
    </div>
</template>

<script>
import BenefitsListItemComponent from "./BenefitsListItem.vue";

export default {
    name: "promo-site-list",
    props: {},
    components: {
        BenefitsListItemComponent,
    },
    data() {
        return {
            list: [
                {
                    title: "Rugalmas template rendszer",
                    description:
                        "A microsite kialakítható design sablon szekciók segítségével. Kiegészíthető új szekciókkal, sorrendezhető, meglévő szekciók átalakíthatóak. Szinte nincs korlát az egyedihez képest, csak előnyök.",
                    number: "1",
                    animationClass: "benefitsFirstListItem",
                },
                {
                    title: "Sztenderd minőség",
                    description:
                        "A szakmailag kiváló ügynökségek is gyakran küzdenek a fejlesztőkkel. Bárkivel is dolgozol, a Prizific sztenderd minőséget biztosít szoftveres oldalon.",
                    number: "2",
                    animationClass: "benefitsSecondListItem",
                },
                {
                    title: "Kiemelkedő UX",
                    description:
                        "Kevesebb kattintás, mobile first. A fogyasztónak mindig tudnia kell, hogy mit kell tennie. Ez a UX alapja. Így tesszük jobbá és jobbá a Prizificet. Minél egyszerűbb a fogyasztó dolga, annál nagyobb vesz részt egy aktivitásban.",
                    number: "3",
                    animationClass: "benefitsThirdListItem",
                },
                {
                    title: "Élő statisztika egy klikkre",
                    description:
                        "Folyamatosan frissülő riportok. Nem kell egy napokat várnod az eredményekre.",
                    number: "4",
                    animationClass: "benefitsFourthListItem",
                },
                {
                    title: "Tapasztalat",
                    description:
                        "Korábban sok iparágból szerzett digitális termékfejlesztési tapasztalatunkat építjük be a Prizificbe, ezért találkozhatsz olyan megoldásokkal, amelyekkel korábban soha.",
                    number: "5",
                    animationClass: "benefitsFifthListItem",
                },
                {
                    title: "Gyűjtsd a követőket!",
                    description:
                        "Több ezer játékos regisztrál be a jelenlegi kampányaidba? Ne veszítsd el őket! A következő kampányban ők lesznek a legmotiváltabb fogyasztóid!",
                    number: "6",
                    animationClass: "benefitsSixthListItem",
                },
                {
                    title: "Kampányindítás pár nap alatt",
                    description:
                        "Néha úgy alakul, hogy szorít az idő. Ilyen esetekben villámsebességgel alakítható ki promo site. Pár napon belül indulásra kész.",
                    number: "7",
                    animationClass: "benefitsSeventhListItem",
                },
                {
                    title: "Integrálható marketingeszközök",
                    description:
                        "Van loyalty rendszeretek? Vagy mobil alkalmazás? Kösd össze őket a Prizifickel! A nyereményjáték mostantól a marketing-vérkeringés részévé válhat.",
                    number: "8",
                    animationClass: "benefitsEighthListItem",
                },
                {
                    title: "Költséghatékonyság",
                    description:
                        "Nem promo site-okat fejlesztünk. Egy digitális szolgáltatást fejlesztünk, amellyel weboldalakat építhtesz fel. Így lehet radikálisan kevesebb az egy kampányra eső HR költségünk, és ezért adhatunk sokkal jobb árat a piaci szereplők zöménél.",
                    number: "9",
                    animationClass: "benefitsNinthListItem",
                },
                {
                    title: "GDPR",
                    description:
                        "A személyes adatokat GDPR kompatibilisen kezeljük.",
                    number: "10",
                    animationClass: "benefitsTenthListItem",
                },
            ],
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>
<style lang="scss">
.btn-more {
    background-color: transparent;
    border: 0;
    color: #f8fbf4;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    align-items: center;

    .material-icons {
        &.up {
            display: none;
        }
    }
}

.collapse {
    &.show + .text-center {
        .btn-more {
            .material-icons {
                &.down {
                    display: none;
                }
                &.up {
                    display: inline;
                }
            }
        }
    }
}
</style>
