export const drawStore = {
    namespaced: true,

    state: () => ({
        drawForm: {
            replacementWinners: 0,
            players: [],
            ignoreFirstRow: true,
            reset: false,
        },
        drawPlayground: {
            showOverlay: false,
            winner: null,
            replacementWinners: [],
        },
    }),

    getters: {
        playersAfterProcess: (state) => {
            if (state.drawForm.ignoreFirstRow) {
                return state.drawForm.players.filter(
                    (player, index) => index !== 0
                );
            } else {
                return state.drawForm.players;
            }
        },

        getNumberOfReWinners: (state) => {
            return state.drawForm.replacementWinners;
        },
    },

    actions: {},

    mutations: {
        /**
         * Set draw replacement winners
         *
         * @param state
         * @param number
         */
        setReplacementWinners(state, number) {
            state.drawForm.replacementWinners = number;
        },

        /**
         * Set draw players
         *
         * @param state
         * @param players
         */
        setPlayers(state, players) {
            state.drawForm.players = players;
        },

        /**
         * Set ignored first row
         *
         * @param state
         * @param value
         */
        setIgnoreFirstRow(state, value) {
            state.drawForm.ignoreFirstRow = value;
        },

        /**
         * Set show overlay
         *
         * @param state
         * @param value
         */
        setShowOverlay(state, value) {
            state.drawPlayground.showOverlay = value;
        },

        /**
         * Set winner
         *
         * @param state
         * @param value
         */
        setWinner(state, value) {
            state.drawPlayground.winner = value;
        },

        /**
         * Set replacement winners
         *
         * @param state
         * @param value
         */
        setReWinners(state, value) {
            state.drawPlayground.replacementWinners = value;
        },

        /**
         * Set reset form
         *
         * @param state
         * @param value
         */
        setResetForm(state, value) {
            state.drawForm.reset = value;
        },
    },
};
