<template>
    <div class="col-md-4" v-for="(text, index) in texts" :key="index">
        <div class="card border-0 px-0 px-md-4 mt-5">
            <div :class="['card-icon', text.iconColor]">
                <!-- <span class="material-icons-outlined">{{ text.icon }}</span> -->
                <img :src="text.icon" alt="" />
            </div>
            <div class="card-content" v-html="text.content"></div>
        </div>
    </div>
</template>

<script>
import { WhatStaticTexts } from "../static-texts/what.js";

export default {
    name: "card",
    data() {
        return {
            texts: new WhatStaticTexts().texts,
        };
    },
};
</script>
