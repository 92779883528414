<template>
    <div ref="menuContainer" class="menu-wrapper">
        <div class="d-flex mt-2 mb-2">
            <template v-for="(menuItem, index) in menuItems" :key="index">
                <a
                    :class="[
                        'menu-item',
                        { active: menuItem.active },
                        { 'pointer-none': disablePointerEvents },
                    ]"
                    :href="menuItem.link"
                >
                    {{ menuItem.name }}
                </a>
            </template>
            <div class="menu-helper">&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "mobile-navbar-secondary-component",
    components: {},
    props: {
        menuItems: {
            required: false,
            default: [
                {
                    name: "LAKOSSÁGI",
                    link: "https://prizific.com",
                    active: false,
                },
                {
                    name: "FMCG",
                    link: "https://b2b.prizific.com",
                    active: true,
                },
                {
                    name: "ÜGYNÖKSÉGI",
                    link: "https://b2b.prizific.com/ugynoksegek",
                    active: false,
                },
            ],
        },
    },
    data() {
        return {
            menuContainer: null,
            disablePointerEvents: false,
            isDragging: false,
            initialX: 0,
            initialScrollLeft: 0,
            velocityX: 0,
            momentumFrameId: 0,
        };
    },
    mounted() {
        this.menuContainer = this.$refs.menuContainer;

        if (this.menuContainer) {
            // Mouse events
            this.menuContainer.addEventListener("mousedown", this.onMouseDown);
            this.menuContainer.addEventListener(
                "mouseleave",
                this.onMouseLeave
            );
            this.menuContainer.addEventListener("mouseup", this.onMouseUp);
            this.menuContainer.addEventListener("mousemove", this.onMouseMove);

            // Touch events (for mobile swipe support)
            this.menuContainer.addEventListener(
                "touchstart",
                this.onTouchStart
            );
            this.menuContainer.addEventListener("touchmove", this.onTouchMove);
            this.menuContainer.addEventListener("touchend", this.onTouchEnd);
        }
    },
    beforeDestroy() {
        if (this.menuContainer) {
            // Remove mouse events
            this.menuContainer.removeEventListener(
                "mousedown",
                this.onMouseDown
            );
            this.menuContainer.removeEventListener(
                "mouseleave",
                this.onMouseLeave
            );
            this.menuContainer.removeEventListener("mouseup", this.onMouseUp);
            this.menuContainer.removeEventListener(
                "mousemove",
                this.onMouseMove
            );

            // Remove touch events (for mobile swipe support)
            this.menuContainer.removeEventListener(
                "touchstart",
                this.onTouchStart
            );
            this.menuContainer.removeEventListener(
                "touchmove",
                this.onTouchMove
            );
            this.menuContainer.removeEventListener("touchend", this.onTouchEnd);
        }
    },
    methods: {
        onMouseDown(event) {
            if (!this.menuContainer) {
                return;
            }

            this.isDragging = true;
            this.initialX = event.pageX - this.menuContainer.offsetLeft;
            this.initialScrollLeft = this.menuContainer.scrollLeft;

            this.cancelMomentumTracking();
        },
        onMouseLeave() {
            this.isDragging = false;
            this.disablePointerEvents = false;
        },
        onMouseUp() {
            this.isDragging = false;
            this.disablePointerEvents = false;

            this.startMomentumTracking();
        },
        onMouseMove(event) {
            if (!this.menuContainer || !this.isDragging) {
                return;
            }

            event.preventDefault();
            this.disablePointerEvents = true;
            const currentX = event.pageX - this.menuContainer.offsetLeft;
            const dragDistance = currentX - this.initialX;
            const previousScrollLeft = this.menuContainer.scrollLeft;

            this.menuContainer.scrollLeft =
                this.initialScrollLeft - dragDistance;
            this.velocityX = this.menuContainer.scrollLeft - previousScrollLeft;
        },
        onTouchStart(event) {
            if (!this.menuContainer) {
                return;
            }

            this.isDragging = true;
            this.initialX =
                event.touches[0].pageX - this.menuContainer.offsetLeft;
            this.initialScrollLeft = this.menuContainer.scrollLeft;

            this.cancelMomentumTracking();
        },
        onTouchMove(event) {
            if (!this.menuContainer || !this.isDragging) {
                return;
            }

            event.preventDefault();
            this.disablePointerEvents = true;
            const currentX =
                event.touches[0].pageX - this.menuContainer.offsetLeft;
            const dragDistance = currentX - this.initialX;
            const previousScrollLeft = this.menuContainer.scrollLeft;

            this.menuContainer.scrollLeft =
                this.initialScrollLeft - dragDistance;
            this.velocityX = this.menuContainer.scrollLeft - previousScrollLeft;
        },
        onTouchEnd() {
            this.isDragging = false;
            this.disablePointerEvents = false;
            this.startMomentumTracking();
        },
        startMomentumTracking() {
            this.cancelMomentumTracking();
            this.momentumFrameId = requestAnimationFrame(this.applyMomentum);
        },
        cancelMomentumTracking() {
            cancelAnimationFrame(this.momentumFrameId);
        },
        applyMomentum() {
            if (!this.menuContainer) return;

            this.menuContainer.scrollLeft += this.velocityX;
            this.velocityX *= 0.95;

            if (Math.abs(this.velocityX) > 0.5) {
                this.momentumFrameId = requestAnimationFrame(
                    this.applyMomentum
                );
            }
        },
    },
};
</script>

<style lang="scss">
.menu-wrapper {
    width: 100vw;
    overflow-x: hidden;
    padding-left: 16px;
    padding-right: 16px;

    .menu-item {
        display: flex;
        align-items: center;
        color: white;
        padding: 12px 24px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 600;
        user-select: none;
        text-wrap: nowrap;
        color: #e44f60;
        border-radius: 0;
        background-color: #f9c357;

        &.pointer-none {
            pointer-events: none;
        }

        &.active {
            font-weight: 600;
            text-decoration: underline;
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        &:nth-last-child(-n + 2) {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        &:last-child {
            margin-right: 16px;
        }
    }

    .menu-helper {
        pointer-events: none;
        user-select: none;
        text-wrap: nowrap;
    }
}
</style>
