<template>
    <button @click="handleClick()" class="btn btn-text">
        Cookie beállítások
    </button>
</template>

<script>
import { mapMutations } from "vuex";

export default {
    name: "open-cookie-settings-button",
    data() {
        return {};
    },
    methods: {
        ...mapMutations({
            setActivePage: "cookieSettingsStore/setActivePage",
            showCookieDialog: "cookieSettingsStore/showCookieDialog",
        }),

        /**
         * Handle cookie settings button
         */
        handleClick() {
            this.setActivePage("settings");
            this.showCookieDialog();
        },
    },
};
</script>
